a {
	color: #47648b;
}

a:hover {
	color: #50709c;
}

a:focus {
	color: #50709c;
}

a:active {
	color: #3e587a;
}

html .text-color-primary,
html .text-primary {
	color: #47648b !important;
}

html .text-color-hover-primary:hover,
html .text-hover-primary:hover {
	color: #47648b !important;
}

html .text-color-secondary,
html .text-secondary {
	color: #484a51 !important;
}

html .text-color-hover-secondary:hover,
html .text-hover-secondary:hover {
	color: #484a51 !important;
}

html .text-color-tertiary,
html .text-tertiary {
	color: #2baab1 !important;
}

html .text-color-hover-tertiary:hover,
html .text-hover-tertiary:hover {
	color: #2baab1 !important;
}

html .text-color-quaternary,
html .text-quaternary {
	color: #383f48 !important;
}

html .text-color-hover-quaternary:hover,
html .text-hover-quaternary:hover {
	color: #383f48 !important;
}

html .text-color-dark,
html .text-dark {
	color: #212529 !important;
}

html .text-color-hover-dark:hover,
html .text-hover-dark:hover {
	color: #212529 !important;
}

html .text-color-light,
html .text-light {
	color: #ffffff !important;
}

html .text-color-hover-light:hover,
html .text-hover-light:hover {
	color: #ffffff !important;
}

.gradient-text-color {
	color: #47648b;
	background: -webkit-gradient(linear, left top, right bottom, from(#47648b), to(#484a51));
	background: linear-gradient(to bottom right, #47648b, #484a51);
	background-image: -webkit-gradient(linear, left top, right top, from(#47648b), to(#484a51));
	background-image: linear-gradient(to right, #47648b, #484a51);
}

html .bg-color-primary,
html .bg-primary {
	background-color: #47648b !important;
}

html .bg-color-hover-primary:hover,
html .bg-hover-primary:hover {
	background-color: #47648b !important;
}

html .bg-color-secondary,
html .bg-secondary {
	background-color: #484a51 !important;
}

html .bg-color-hover-secondary:hover,
html .bg-hover-secondary:hover {
	background-color: #484a51 !important;
}

html .bg-color-tertiary,
html .bg-tertiary {
	background-color: #2baab1 !important;
}

html .bg-color-hover-tertiary:hover,
html .bg-hover-tertiary:hover {
	background-color: #2baab1 !important;
}

html .bg-color-quaternary,
html .bg-quaternary {
	background-color: #383f48 !important;
}

html .bg-color-hover-quaternary:hover,
html .bg-hover-quaternary:hover {
	background-color: #383f48 !important;
}

html .bg-color-dark,
html .bg-dark {
	background-color: #212529 !important;
}

html .bg-color-hover-dark:hover,
html .bg-hover-dark:hover {
	background-color: #212529 !important;
}

html .bg-color-light,
html .bg-light {
	background-color: #ffffff !important;
}

html .bg-color-hover-light:hover,
html .bg-hover-light:hover {
	background-color: #ffffff !important;
}
/* Color Transition */
@-webkit-keyframes colorTransition {
	0% {
		background-color: #47648b;
	}

	33% {
		background-color: #484a51;
	}

	66% {
		background-color: #2baab1;
	}

	100% {
		background-color: #383f48;
	}
}

@keyframes colorTransition {
	0% {
		background-color: #47648b;
	}

	33% {
		background-color: #484a51;
	}

	66% {
		background-color: #2baab1;
	}

	100% {
		background-color: #383f48;
	}
}

html .border-color-primary {
	border-color: #47648b !important;
}

html .border-color-hover-primary:hover {
	border-color: #47648b !important;
}

html .border-color-secondary {
	border-color: #484a51 !important;
}

html .border-color-hover-secondary:hover {
	border-color: #484a51 !important;
}

html .border-color-tertiary {
	border-color: #2baab1 !important;
}

html .border-color-hover-tertiary:hover {
	border-color: #2baab1 !important;
}

html .border-color-quaternary {
	border-color: #383f48 !important;
}

html .border-color-hover-quaternary:hover {
	border-color: #383f48 !important;
}

html .border-color-dark {
	border-color: #212529 !important;
}

html .border-color-hover-dark:hover {
	border-color: #212529 !important;
}

html .border-color-light {
	border-color: #ffffff !important;
}

html .border-color-hover-light:hover {
	border-color: #ffffff !important;
}

.alternative-font {
	color: #47648b;
}

html .box-shadow-1-primary:before {
	-webkit-box-shadow: 0 30px 90px #47648b !important;
	box-shadow: 0 30px 90px #47648b !important;
}

html .box-shadow-1-secondary:before {
	-webkit-box-shadow: 0 30px 90px #484a51 !important;
	box-shadow: 0 30px 90px #484a51 !important;
}

html .box-shadow-1-tertiary:before {
	-webkit-box-shadow: 0 30px 90px #2baab1 !important;
	box-shadow: 0 30px 90px #2baab1 !important;
}

html .box-shadow-1-quaternary:before {
	-webkit-box-shadow: 0 30px 90px #383f48 !important;
	box-shadow: 0 30px 90px #383f48 !important;
}

html .box-shadow-1-dark:before {
	-webkit-box-shadow: 0 30px 90px #212529 !important;
	box-shadow: 0 30px 90px #212529 !important;
}

html .box-shadow-1-light:before {
	-webkit-box-shadow: 0 30px 90px #ffffff !important;
	box-shadow: 0 30px 90px #ffffff !important;
}

html .blockquote-primary {
	border-color: #47648b !important;
}

html .blockquote-secondary {
	border-color: #484a51 !important;
}

html .blockquote-tertiary {
	border-color: #2baab1 !important;
}

html .blockquote-quaternary {
	border-color: #383f48 !important;
}

html .blockquote-dark {
	border-color: #212529 !important;
}

html .blockquote-light {
	border-color: #ffffff !important;
}

p.drop-caps:first-letter {
	color: #47648b;
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #47648b;
}

html .nav-color-primary nav > ul > li > a {
	color: #47648b !important;
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #47648b !important;
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #47648b !important;
}

html .nav-color-secondary nav > ul > li > a {
	color: #484a51 !important;
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #484a51 !important;
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #484a51 !important;
}

html .nav-color-tertiary nav > ul > li > a {
	color: #2baab1 !important;
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #2baab1 !important;
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #2baab1 !important;
}

html .nav-color-quaternary nav > ul > li > a {
	color: #383f48 !important;
}

html .nav-color-quaternary:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #383f48 !important;
}

html .nav-color-quaternary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #383f48 !important;
}

html .nav-color-dark nav > ul > li > a {
	color: #212529 !important;
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #212529 !important;
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #212529 !important;
}

html .nav-color-light nav > ul > li > a {
	color: #ffffff !important;
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
	background-color: #ffffff !important;
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
	border-top-color: #ffffff !important;
}

.nav-pills > li.active > a,
.nav-pills .nav-link.active {
	background-color: #47648b;
}

.nav-pills > li.active > a:hover,
.nav-pills .nav-link.active:hover,
.nav-pills > li.active > a:focus,
.nav-pills .nav-link.active:focus {
	background-color: #47648b;
}

.nav-active-style-1 > li > a:hover,
.nav-active-style-1 > li > a:focus,
.nav-active-style-1 > li > a.active {
	border-bottom-color: #47648b;
}

html .nav-pills-primary a {
	color: #47648b;
}

html .nav-pills-primary a:hover {
	color: #50709c;
}

html .nav-pills-primary a:focus {
	color: #50709c;
}

html .nav-pills-primary a:active {
	color: #3e587a;
}

html .nav-pills-primary .nav-link.active,
html .nav-pills-primary > li.active > a {
	background-color: #47648b;
}

html .nav-pills-primary .nav-link.active:hover,
html .nav-pills-primary > li.active > a:hover,
html .nav-pills-primary .nav-link.active:focus,
html .nav-pills-primary > li.active > a:focus {
	background-color: #47648b;
}

html .nav-pills-secondary a {
	color: #484a51;
}

html .nav-pills-secondary a:hover {
	color: #54565e;
}

html .nav-pills-secondary a:focus {
	color: #54565e;
}

html .nav-pills-secondary a:active {
	color: #3c3e44;
}

html .nav-pills-secondary .nav-link.active,
html .nav-pills-secondary > li.active > a {
	background-color: #484a51;
}

html .nav-pills-secondary .nav-link.active:hover,
html .nav-pills-secondary > li.active > a:hover,
html .nav-pills-secondary .nav-link.active:focus,
html .nav-pills-secondary > li.active > a:focus {
	background-color: #484a51;
}

html .nav-pills-tertiary a {
	color: #2baab1;
}

html .nav-pills-tertiary a:hover {
	color: #30bec6;
}

html .nav-pills-tertiary a:focus {
	color: #30bec6;
}

html .nav-pills-tertiary a:active {
	color: #26969c;
}

html .nav-pills-tertiary .nav-link.active,
html .nav-pills-tertiary > li.active > a {
	background-color: #2baab1;
}

html .nav-pills-tertiary .nav-link.active:hover,
html .nav-pills-tertiary > li.active > a:hover,
html .nav-pills-tertiary .nav-link.active:focus,
html .nav-pills-tertiary > li.active > a:focus {
	background-color: #2baab1;
}

html .nav-pills-quaternary a {
	color: #383f48;
}

html .nav-pills-quaternary a:hover {
	color: #434c56;
}

html .nav-pills-quaternary a:focus {
	color: #434c56;
}

html .nav-pills-quaternary a:active {
	color: #2d323a;
}

html .nav-pills-quaternary .nav-link.active,
html .nav-pills-quaternary > li.active > a {
	background-color: #383f48;
}

html .nav-pills-quaternary .nav-link.active:hover,
html .nav-pills-quaternary > li.active > a:hover,
html .nav-pills-quaternary .nav-link.active:focus,
html .nav-pills-quaternary > li.active > a:focus {
	background-color: #383f48;
}

html .nav-pills-dark a {
	color: #212529;
}

html .nav-pills-dark a:hover {
	color: #2c3237;
}

html .nav-pills-dark a:focus {
	color: #2c3237;
}

html .nav-pills-dark a:active {
	color: #16181b;
}

html .nav-pills-dark .nav-link.active,
html .nav-pills-dark > li.active > a {
	background-color: #212529;
}

html .nav-pills-dark .nav-link.active:hover,
html .nav-pills-dark > li.active > a:hover,
html .nav-pills-dark .nav-link.active:focus,
html .nav-pills-dark > li.active > a:focus {
	background-color: #212529;
}

html .nav-pills-light a {
	color: #ffffff;
}

html .nav-pills-light a:hover {
	color: #ffffff;
}

html .nav-pills-light a:focus {
	color: #ffffff;
}

html .nav-pills-light a:active {
	color: #f2f2f2;
}

html .nav-pills-light .nav-link.active,
html .nav-pills-light > li.active > a {
	background-color: #ffffff;
}

html .nav-pills-light .nav-link.active:hover,
html .nav-pills-light > li.active > a:hover,
html .nav-pills-light .nav-link.active:focus,
html .nav-pills-light > li.active > a:focus {
	background-color: #ffffff;
}

.section-scroll-dots-navigation.scroll-dots-navigation-colored > ul > li > a:before {
	background: #47648b;
}

.sort-source-wrapper .nav > li.active > a {
	color: #47648b;
}

.sort-source-wrapper .nav > li.active > a:hover,
.sort-source-wrapper .nav > li.active > a:focus {
	color: #47648b;
}

.sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #47648b;
}

.sort-source.sort-source-style-3 > li.active > a {
	border-bottom-color: #47648b !important;
	color: #47648b !important;
}

html .badge-primary {
	background-color: #47648b;
}

html .badge-secondary {
	background-color: #484a51;
}

html .badge-tertiary {
	background-color: #2baab1;
}

html .badge-quaternary {
	background-color: #383f48;
}

html .badge-dark {
	background-color: #212529;
}

html .badge-light {
	background-color: #ffffff;
}

html .overlay-color-primary:not(.no-skin):before {
	background-color: #47648b !important;
}

html .overlay-color-secondary:not(.no-skin):before {
	background-color: #484a51 !important;
}

html .overlay-color-tertiary:not(.no-skin):before {
	background-color: #2baab1 !important;
}

html .overlay-color-quaternary:not(.no-skin):before {
	background-color: #383f48 !important;
}

html .overlay-color-dark:not(.no-skin):before {
	background-color: #212529 !important;
}

html .overlay-color-light:not(.no-skin):before {
	background-color: #ffffff !important;
}

.btn-link {
	color: #47648b;
}

.btn-link:hover {
	color: #50709c;
}

.btn-link:active {
	color: #3e587a;
}

html .btn-primary {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
	color: #ffffff;
}

html .btn-primary:hover,
html .btn-primary.hover {
	background-color: #5476a4;
	border-color: #5a7dab #5a7dab #47648b;
	color: #ffffff;
}

html .btn-primary:focus,
html .btn-primary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

html .btn-primary.disabled,
html .btn-primary:disabled {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
}

html .btn-primary:active,
html .btn-primary.active {
	background-color: #3a5272 !important;
	background-image: none !important;
	border-color: #364c69 #364c69 #253347 !important;
}

html .btn-primary-scale-2 {
	background-color: #364c69;
	border-color: #364c69 #364c69 #253347;
	color: #ffffff;
}

html .btn-primary-scale-2:hover,
html .btn-primary-scale-2.hover {
	background-color: #435e83;
	border-color: #47648b #47648b #364c69;
	color: #ffffff;
}

html .btn-primary-scale-2:focus,
html .btn-primary-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(54, 76, 105, 0.5);
	box-shadow: 0 0 0 3px rgba(54, 76, 105, 0.5);
}

html .btn-primary-scale-2.disabled,
html .btn-primary-scale-2:disabled {
	background-color: #364c69;
	border-color: #364c69 #364c69 #253347;
}

html .btn-primary-scale-2:active,
html .btn-primary-scale-2.active {
	background-color: #293950 !important;
	background-image: none !important;
	border-color: #253347 #253347 #131b26 !important;
}

html .show > .btn-primary.dropdown-toggle,
html .show > .btn-primary-scale-2.dropdown-toggle {
	background-color: #3a5272 !important;
	background-image: none !important;
	border-color: #364c69 #364c69 #253347 !important;
}

html .btn-secondary {
	background-color: #484a51;
	border-color: #484a51 #484a51 #303136;
	color: #ffffff;
}

html .btn-secondary:hover,
html .btn-secondary.hover {
	background-color: #5a5d65;
	border-color: #60636c #60636c #484a51;
	color: #ffffff;
}

html .btn-secondary:focus,
html .btn-secondary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(72, 74, 81, 0.5);
	box-shadow: 0 0 0 3px rgba(72, 74, 81, 0.5);
}

html .btn-secondary.disabled,
html .btn-secondary:disabled {
	background-color: #484a51;
	border-color: #484a51 #484a51 #303136;
}

html .btn-secondary:active,
html .btn-secondary.active {
	background-color: #36383d !important;
	background-image: none !important;
	border-color: #303136 #303136 #18191b !important;
}

html .btn-secondary-scale-2 {
	background-color: #303136;
	border-color: #303136 #303136 #18191b;
	color: #ffffff;
}

html .btn-secondary-scale-2:hover,
html .btn-secondary-scale-2.hover {
	background-color: #42444a;
	border-color: #484a51 #484a51 #303136;
	color: #ffffff;
}

html .btn-secondary-scale-2:focus,
html .btn-secondary-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(48, 49, 54, 0.5);
	box-shadow: 0 0 0 3px rgba(48, 49, 54, 0.5);
}

html .btn-secondary-scale-2.disabled,
html .btn-secondary-scale-2:disabled {
	background-color: #303136;
	border-color: #303136 #303136 #18191b;
}

html .btn-secondary-scale-2:active,
html .btn-secondary-scale-2.active {
	background-color: #1e1f22 !important;
	background-image: none !important;
	border-color: #18191b #18191b #000000 !important;
}

html .show > .btn-secondary.dropdown-toggle,
html .show > .btn-secondary-scale-2.dropdown-toggle {
	background-color: #36383d !important;
	background-image: none !important;
	border-color: #303136 #303136 #18191b !important;
}

html .btn-tertiary {
	background-color: #2baab1;
	border-color: #2baab1 #2baab1 #218388;
	color: #ffffff;
}

html .btn-tertiary:hover,
html .btn-tertiary.hover {
	background-color: #34c6ce;
	border-color: #3fc9d0 #3fc9d0 #2baab1;
	color: #ffffff;
}

html .btn-tertiary:focus,
html .btn-tertiary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
	box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
}

html .btn-tertiary.disabled,
html .btn-tertiary:disabled {
	background-color: #2baab1;
	border-color: #2baab1 #2baab1 #218388;
}

html .btn-tertiary:active,
html .btn-tertiary.active {
	background-color: #248c92 !important;
	background-image: none !important;
	border-color: #218388 #218388 #175b5f !important;
}

html .btn-tertiary-scale-2 {
	background-color: #218388;
	border-color: #218388 #218388 #175b5f;
	color: #ffffff;
}

html .btn-tertiary-scale-2:hover,
html .btn-tertiary-scale-2.hover {
	background-color: #29a0a7;
	border-color: #2baab1 #2baab1 #218388;
	color: #ffffff;
}

html .btn-tertiary-scale-2:focus,
html .btn-tertiary-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(33, 131, 136, 0.5);
	box-shadow: 0 0 0 3px rgba(33, 131, 136, 0.5);
}

html .btn-tertiary-scale-2.disabled,
html .btn-tertiary-scale-2:disabled {
	background-color: #218388;
	border-color: #218388 #218388 #175b5f;
}

html .btn-tertiary-scale-2:active,
html .btn-tertiary-scale-2.active {
	background-color: #1a6569 !important;
	background-image: none !important;
	border-color: #175b5f #175b5f #0d3436 !important;
}

html .show > .btn-tertiary.dropdown-toggle,
html .show > .btn-tertiary-scale-2.dropdown-toggle {
	background-color: #248c92 !important;
	background-image: none !important;
	border-color: #218388 #218388 #175b5f !important;
}

html .btn-quaternary {
	background-color: #383f48;
	border-color: #383f48 #383f48 #22262b;
	color: #ffffff;
}

html .btn-quaternary:hover,
html .btn-quaternary.hover {
	background-color: #49525e;
	border-color: #4e5865 #4e5865 #383f48;
	color: #ffffff;
}

html .btn-quaternary:focus,
html .btn-quaternary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(56, 63, 72, 0.5);
	box-shadow: 0 0 0 3px rgba(56, 63, 72, 0.5);
}

html .btn-quaternary.disabled,
html .btn-quaternary:disabled {
	background-color: #383f48;
	border-color: #383f48 #383f48 #22262b;
}

html .btn-quaternary:active,
html .btn-quaternary.active {
	background-color: #272c32 !important;
	background-image: none !important;
	border-color: #22262b #22262b #0b0d0f !important;
}

html .btn-quaternary-scale-2 {
	background-color: #22262b;
	border-color: #22262b #22262b #0b0d0f;
	color: #ffffff;
}

html .btn-quaternary-scale-2:hover,
html .btn-quaternary-scale-2.hover {
	background-color: #323941;
	border-color: #383f48 #383f48 #22262b;
	color: #ffffff;
}

html .btn-quaternary-scale-2:focus,
html .btn-quaternary-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(34, 38, 43, 0.5);
	box-shadow: 0 0 0 3px rgba(34, 38, 43, 0.5);
}

html .btn-quaternary-scale-2.disabled,
html .btn-quaternary-scale-2:disabled {
	background-color: #22262b;
	border-color: #22262b #22262b #0b0d0f;
}

html .btn-quaternary-scale-2:active,
html .btn-quaternary-scale-2.active {
	background-color: #111316 !important;
	background-image: none !important;
	border-color: #0b0d0f #0b0d0f #000000 !important;
}

html .show > .btn-quaternary.dropdown-toggle,
html .show > .btn-quaternary-scale-2.dropdown-toggle {
	background-color: #272c32 !important;
	background-image: none !important;
	border-color: #22262b #22262b #0b0d0f !important;
}

html .btn-dark {
	background-color: #212529;
	border-color: #212529 #212529 #0a0c0d;
	color: #ffffff;
}

html .btn-dark:hover,
html .btn-dark.hover {
	background-color: #32383e;
	border-color: #383f45 #383f45 #212529;
	color: #ffffff;
}

html .btn-dark:focus,
html .btn-dark.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
	box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

html .btn-dark.disabled,
html .btn-dark:disabled {
	background-color: #212529;
	border-color: #212529 #212529 #0a0c0d;
}

html .btn-dark:active,
html .btn-dark.active {
	background-color: #101214 !important;
	background-image: none !important;
	border-color: #0a0c0d #0a0c0d #000000 !important;
}

html .btn-dark-scale-2 {
	background-color: #0a0c0d;
	border-color: #0a0c0d #0a0c0d #000000;
	color: #ffffff;
}

html .btn-dark-scale-2:hover,
html .btn-dark-scale-2.hover {
	background-color: #1b1f22;
	border-color: #212529 #212529 #0a0c0d;
	color: #ffffff;
}

html .btn-dark-scale-2:focus,
html .btn-dark-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(10, 11, 13, 0.5);
	box-shadow: 0 0 0 3px rgba(10, 11, 13, 0.5);
}

html .btn-dark-scale-2.disabled,
html .btn-dark-scale-2:disabled {
	background-color: #0a0c0d;
	border-color: #0a0c0d #0a0c0d #000000;
}

html .btn-dark-scale-2:active,
html .btn-dark-scale-2.active {
	background-color: #000000 !important;
	background-image: none !important;
	border-color: #000000 #000000 #000000 !important;
}

html .show > .btn-dark.dropdown-toggle,
html .show > .btn-dark-scale-2.dropdown-toggle {
	background-color: #101214 !important;
	background-image: none !important;
	border-color: #0a0c0d #0a0c0d #000000 !important;
}

html .btn-light {
	background-color: #ffffff;
	border-color: #ffffff #ffffff #e6e6e6;
	color: #777777;
}

html .btn-light:hover,
html .btn-light.hover {
	background-color: #ffffff;
	border-color: #ffffff #ffffff #ffffff;
	color: #777777;
}

html .btn-light:focus,
html .btn-light.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-light.disabled,
html .btn-light:disabled {
	background-color: #ffffff;
	border-color: #ffffff #ffffff #e6e6e6;
}

html .btn-light:active,
html .btn-light.active {
	background-color: #ececec !important;
	background-image: none !important;
	border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

html .btn-light-scale-2 {
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #cccccc;
	color: #777777;
}

html .btn-light-scale-2:hover,
html .btn-light-scale-2.hover {
	background-color: #f9f9f9;
	border-color: #ffffff #ffffff #e6e6e6;
	color: #777777;
}

html .btn-light-scale-2:focus,
html .btn-light-scale-2.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(230, 230, 230, 0.5);
	box-shadow: 0 0 0 3px rgba(230, 230, 230, 0.5);
}

html .btn-light-scale-2.disabled,
html .btn-light-scale-2:disabled {
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #cccccc;
}

html .btn-light-scale-2:active,
html .btn-light-scale-2.active {
	background-color: #d2d2d2 !important;
	background-image: none !important;
	border-color: #cccccc #cccccc #b3b3b3 !important;
}

html .show > .btn-light.dropdown-toggle,
html .show > .btn-light-scale-2.dropdown-toggle {
	background-color: #ececec !important;
	background-image: none !important;
	border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

html .btn-outline.btn-primary {
	color: #47648b;
	background-color: transparent;
	background-image: none;
	border-color: #47648b;
}

html .btn-outline.btn-primary:hover,
html .btn-outline.btn-primary.hover {
	color: #ffffff;
	background-color: #47648b;
	border-color: #47648b;
}

html .btn-outline.btn-primary:focus,
html .btn-outline.btn-primary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

html .btn-outline.btn-primary.disabled,
html .btn-outline.btn-primary:disabled {
	color: #47648b;
	background-color: transparent;
}

html .btn-outline.btn-primary:active,
html .btn-outline.btn-primary.active {
	color: #ffffff !important;
	background-color: #47648b !important;
	border-color: #47648b !important;
}

html .show > .btn-outline.btn-primary.dropdown-toggle {
	color: #ffffff !important;
	background-color: #47648b !important;
	border-color: #47648b !important;
}

html .btn-outline.btn-secondary {
	color: #484a51;
	background-color: transparent;
	background-image: none;
	border-color: #484a51;
}

html .btn-outline.btn-secondary:hover,
html .btn-outline.btn-secondary.hover {
	color: #ffffff;
	background-color: #484a51;
	border-color: #484a51;
}

html .btn-outline.btn-secondary:focus,
html .btn-outline.btn-secondary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(72, 74, 81, 0.5);
	box-shadow: 0 0 0 3px rgba(72, 74, 81, 0.5);
}

html .btn-outline.btn-secondary.disabled,
html .btn-outline.btn-secondary:disabled {
	color: #484a51;
	background-color: transparent;
}

html .btn-outline.btn-secondary:active,
html .btn-outline.btn-secondary.active {
	color: #ffffff !important;
	background-color: #484a51 !important;
	border-color: #484a51 !important;
}

html .show > .btn-outline.btn-secondary.dropdown-toggle {
	color: #ffffff !important;
	background-color: #484a51 !important;
	border-color: #484a51 !important;
}

html .btn-outline.btn-tertiary {
	color: #2baab1;
	background-color: transparent;
	background-image: none;
	border-color: #2baab1;
}

html .btn-outline.btn-tertiary:hover,
html .btn-outline.btn-tertiary.hover {
	color: #ffffff;
	background-color: #2baab1;
	border-color: #2baab1;
}

html .btn-outline.btn-tertiary:focus,
html .btn-outline.btn-tertiary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
	box-shadow: 0 0 0 3px rgba(43, 170, 177, 0.5);
}

html .btn-outline.btn-tertiary.disabled,
html .btn-outline.btn-tertiary:disabled {
	color: #2baab1;
	background-color: transparent;
}

html .btn-outline.btn-tertiary:active,
html .btn-outline.btn-tertiary.active {
	color: #ffffff !important;
	background-color: #2baab1 !important;
	border-color: #2baab1 !important;
}

html .show > .btn-outline.btn-tertiary.dropdown-toggle {
	color: #ffffff !important;
	background-color: #2baab1 !important;
	border-color: #2baab1 !important;
}

html .btn-outline.btn-quaternary {
	color: #383f48;
	background-color: transparent;
	background-image: none;
	border-color: #383f48;
}

html .btn-outline.btn-quaternary:hover,
html .btn-outline.btn-quaternary.hover {
	color: #ffffff;
	background-color: #383f48;
	border-color: #383f48;
}

html .btn-outline.btn-quaternary:focus,
html .btn-outline.btn-quaternary.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(56, 63, 72, 0.5);
	box-shadow: 0 0 0 3px rgba(56, 63, 72, 0.5);
}

html .btn-outline.btn-quaternary.disabled,
html .btn-outline.btn-quaternary:disabled {
	color: #383f48;
	background-color: transparent;
}

html .btn-outline.btn-quaternary:active,
html .btn-outline.btn-quaternary.active {
	color: #ffffff !important;
	background-color: #383f48 !important;
	border-color: #383f48 !important;
}

html .show > .btn-outline.btn-quaternary.dropdown-toggle {
	color: #ffffff !important;
	background-color: #383f48 !important;
	border-color: #383f48 !important;
}

html .btn-outline.btn-dark {
	color: #212529;
	background-color: transparent;
	background-image: none;
	border-color: #212529;
}

html .btn-outline.btn-dark:hover,
html .btn-outline.btn-dark.hover {
	color: #ffffff;
	background-color: #212529;
	border-color: #212529;
}

html .btn-outline.btn-dark:focus,
html .btn-outline.btn-dark.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
	box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

html .btn-outline.btn-dark.disabled,
html .btn-outline.btn-dark:disabled {
	color: #212529;
	background-color: transparent;
}

html .btn-outline.btn-dark:active,
html .btn-outline.btn-dark.active {
	color: #ffffff !important;
	background-color: #212529 !important;
	border-color: #212529 !important;
}

html .show > .btn-outline.btn-dark.dropdown-toggle {
	color: #ffffff !important;
	background-color: #212529 !important;
	border-color: #212529 !important;
}

html .btn-outline.btn-light {
	color: #ffffff;
	background-color: transparent;
	background-image: none;
	border-color: #ffffff;
}

html .btn-outline.btn-light:hover,
html .btn-outline.btn-light.hover {
	color: #777777;
	background-color: #ffffff;
	border-color: #ffffff;
}

html .btn-outline.btn-light:focus,
html .btn-outline.btn-light.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-outline.btn-light.disabled,
html .btn-outline.btn-light:disabled {
	color: #ffffff;
	background-color: transparent;
}

html .btn-outline.btn-light:active,
html .btn-outline.btn-light.active {
	color: #777777 !important;
	background-color: #ffffff !important;
	border-color: #ffffff !important;
}

html .show > .btn-outline.btn-light.dropdown-toggle {
	color: #777777 !important;
	background-color: #ffffff !important;
	border-color: #ffffff !important;
}

html .btn-with-arrow.btn-primary {
	background-color: transparent;
	border-color: transparent;
	color: #47648b;
}

html .btn-with-arrow.btn-primary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #47648b !important;
}

html .btn-with-arrow.btn-primary span {
	background-color: #47648b;
	-webkit-box-shadow: 2px 3px 18px -3px #47648b;
	box-shadow: 2px 3px 18px -3px #47648b;
}

html .btn-with-arrow.btn-primary.btn-outline {
	border-color: #47648b !important;
}

html .btn-with-arrow.btn-primary.btn-outline:hover span {
	background-color: #50709c !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html .btn-with-arrow.btn-secondary {
	background-color: transparent;
	border-color: transparent;
	color: #484a51;
}

html .btn-with-arrow.btn-secondary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #484a51 !important;
}

html .btn-with-arrow.btn-secondary span {
	background-color: #484a51;
	-webkit-box-shadow: 2px 3px 18px -3px #484a51;
	box-shadow: 2px 3px 18px -3px #484a51;
}

html .btn-with-arrow.btn-secondary.btn-outline {
	border-color: #484a51 !important;
}

html .btn-with-arrow.btn-secondary.btn-outline:hover span {
	background-color: #54565e !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html .btn-with-arrow.btn-tertiary {
	background-color: transparent;
	border-color: transparent;
	color: #2baab1;
}

html .btn-with-arrow.btn-tertiary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #2baab1 !important;
}

html .btn-with-arrow.btn-tertiary span {
	background-color: #2baab1;
	-webkit-box-shadow: 2px 3px 18px -3px #2baab1;
	box-shadow: 2px 3px 18px -3px #2baab1;
}

html .btn-with-arrow.btn-tertiary.btn-outline {
	border-color: #2baab1 !important;
}

html .btn-with-arrow.btn-tertiary.btn-outline:hover span {
	background-color: #30bec6 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html .btn-with-arrow.btn-quaternary {
	background-color: transparent;
	border-color: transparent;
	color: #383f48;
}

html .btn-with-arrow.btn-quaternary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #383f48 !important;
}

html .btn-with-arrow.btn-quaternary span {
	background-color: #383f48;
	-webkit-box-shadow: 2px 3px 18px -3px #383f48;
	box-shadow: 2px 3px 18px -3px #383f48;
}

html .btn-with-arrow.btn-quaternary.btn-outline {
	border-color: #383f48 !important;
}

html .btn-with-arrow.btn-quaternary.btn-outline:hover span {
	background-color: #434c56 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html .btn-with-arrow.btn-dark {
	background-color: transparent;
	border-color: transparent;
	color: #212529;
}

html .btn-with-arrow.btn-dark:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #212529 !important;
}

html .btn-with-arrow.btn-dark span {
	background-color: #212529;
	-webkit-box-shadow: 2px 3px 18px -3px #212529;
	box-shadow: 2px 3px 18px -3px #212529;
}

html .btn-with-arrow.btn-dark.btn-outline {
	border-color: #212529 !important;
}

html .btn-with-arrow.btn-dark.btn-outline:hover span {
	background-color: #2c3237 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html .btn-with-arrow.btn-light {
	background-color: transparent;
	border-color: transparent;
	color: #ffffff;
}

html .btn-with-arrow.btn-light:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #ffffff !important;
}

html .btn-with-arrow.btn-light span {
	background-color: #ffffff;
	-webkit-box-shadow: 2px 3px 18px -3px #ffffff;
	box-shadow: 2px 3px 18px -3px #ffffff;
}

html .btn-with-arrow.btn-light.btn-outline {
	border-color: #ffffff !important;
}

html .btn-with-arrow.btn-light.btn-outline:hover span {
	background-color: #ffffff !important;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-gradient:not(.btn-outline) {
	background: linear-gradient(135deg, #47648b 0%, #484a51 80%);
	color: #ffffff;
}

.btn-gradient:not(.btn-outline):hover,
.btn-gradient:not(.btn-outline).hover {
	background: linear-gradient(135deg, #5476a4 0%, #5a5d65 80%);
	color: #ffffff;
}

.btn-gradient:not(.btn-outline):focus,
.btn-gradient:not(.btn-outline).focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

.btn-gradient:not(.btn-outline).disabled,
.btn-gradient:not(.btn-outline):disabled {
	background: linear-gradient(135deg, #47648b 0%, #484a51 80%);
}

.btn-gradient:not(.btn-outline):active,
.btn-gradient:not(.btn-outline).active {
	background: linear-gradient(135deg, #3a5272 0%, #36383d 80%) !important;
	color: #ffffff !important;
}

.btn-gradient.btn-outline {
	-o-border-image: linear-gradient(135deg, #47648b 0%, #484a51 80%);
	border-image: linear-gradient(135deg, #47648b 0%, #484a51 80%);
	color: #47648b;
}

.btn-gradient.btn-outline:hover,
.btn-gradient.btn-outline.hover {
	-o-border-image: linear-gradient(135deg, #5476a4 0%, #5a5d65 80%);
	border-image: linear-gradient(135deg, #5476a4 0%, #5a5d65 80%);
	color: #5476a4;
}

.btn-gradient.btn-outline:focus,
.btn-gradient.btn-outline.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

.btn-gradient.btn-outline.disabled,
.btn-gradient.btn-outline:disabled {
	-o-border-image: linear-gradient(135deg, #47648b 0%, #484a51 80%);
	border-image: linear-gradient(135deg, #47648b 0%, #484a51 80%);
}

.btn-gradient.btn-outline:active,
.btn-gradient.btn-outline.active {
	-o-border-image: linear-gradient(135deg, #3a5272 0%, #36383d 80%) !important;
	border-image: linear-gradient(135deg, #3a5272 0%, #36383d 80%) !important;
	color: #3a5272;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #47648b;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination .page-item.active .page-link {
	background-color: #47648b !important;
	border-color: #47648b;
}

body.dark .pagination > li > a,
body.dark .pagination > li > span,
body.dark .pagination > li > a:hover,
body.dark .pagination > li > span:hover,
body.dark .pagination > li > a:focus,
body.dark .pagination > li > span:focus {
	color: #47648b;
}

body.dark .pagination > .active > a,
body.dark .pagination > .active > span,
body.dark .pagination > .active > a:hover,
body.dark .pagination > .active > span:hover,
body.dark .pagination > .active > a:focus,
body.dark .pagination > .active > span:focus {
	background-color: #47648b;
	border-color: #47648b;
}

.pagination > .active > a,
body.dark .pagination > .active > a {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
	color: #ffffff;
}

.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover,
.pagination > .active > a.hover,
body.dark .pagination > .active > a.hover {
	background-color: #5476a4;
	border-color: #5a7dab #5a7dab #47648b;
	color: #ffffff;
}

.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.pagination > .active > a.focus,
body.dark .pagination > .active > a.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

.pagination > .active > a.disabled,
body.dark .pagination > .active > a.disabled,
.pagination > .active > a:disabled,
body.dark .pagination > .active > a:disabled {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
}

.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.pagination > .active > a.active,
body.dark .pagination > .active > a.active {
	background-color: #3a5272 !important;
	background-image: none !important;
	border-color: #364c69 #364c69 #253347 !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #47648b;
}

html .alert-primary {
	background-color: #47648b;
	border-color: #425d81;
	color: #ffffff;
}

html .alert-primary .alert-link {
	color: #ffffff;
}

html .alert-secondary {
	background-color: #484a51;
	border-color: #414349;
	color: #ffffff;
}

html .alert-secondary .alert-link {
	color: #ffffff;
}

html .alert-tertiary {
	background-color: #2baab1;
	border-color: #289ea5;
	color: #ffffff;
}

html .alert-tertiary .alert-link {
	color: #ffffff;
}

html .alert-quaternary {
	background-color: #383f48;
	border-color: #31373f;
	color: #ffffff;
}

html .alert-quaternary .alert-link {
	color: #ffffff;
}

html .alert-dark {
	background-color: #212529;
	border-color: #1a1d21;
	color: #ffffff;
}

html .alert-dark .alert-link {
	color: #ffffff;
}

html .alert-light {
	background-color: #ffffff;
	border-color: #f7f7f7;
	color: #777777;
}

html .alert-light .alert-link {
	color: #777777;
}

html .progress-bar-primary {
	background-color: #47648b;
}

html .progress-bar-secondary {
	background-color: #484a51;
}

html .progress-bar-tertiary {
	background-color: #2baab1;
}

html .progress-bar-quaternary {
	background-color: #383f48;
}

html .progress-bar-dark {
	background-color: #212529;
}

html .progress-bar-light {
	background-color: #ffffff;
}

html .word-rotator.loading-bar-primary .word-rotator-words::after {
	background-color: #47648b;
}

html .word-rotator.loading-bar-secondary .word-rotator-words::after {
	background-color: #484a51;
}

html .word-rotator.loading-bar-tertiary .word-rotator-words::after {
	background-color: #2baab1;
}

html .word-rotator.loading-bar-quaternary .word-rotator-words::after {
	background-color: #383f48;
}

html .word-rotator.loading-bar-dark .word-rotator-words::after {
	background-color: #212529;
}

html .word-rotator.loading-bar-light .word-rotator-words::after {
	background-color: #ffffff;
}

.process .process-step-circle {
	border-color: #47648b;
}

.process .process-step:hover .process-step-circle {
	background-color: #47648b;
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
	color: #ffffff !important;
}

.process-connecting-line .connecting-line {
	background: #47648b;
}

html section.section-primary {
	background-color: #47648b !important;
	border-color: #3e587a !important;
}

html section.section-primary h1,
html section.section-primary h2,
html section.section-primary h3,
html section.section-primary h4,
html section.section-primary h5,
html section.section-primary h6 {
	color: #ffffff;
}

html section.section-primary p {
	color: #e6e6e6;
}

html section.section-primary-scale-2 {
	background-color: #364c69 !important;
	border-color: #2d4058 !important;
}

html section.section-primary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #364c69;
}

html section.section-secondary {
	background-color: #484a51 !important;
	border-color: #3c3e44 !important;
}

html section.section-secondary h1,
html section.section-secondary h2,
html section.section-secondary h3,
html section.section-secondary h4,
html section.section-secondary h5,
html section.section-secondary h6 {
	color: #ffffff;
}

html section.section-secondary p {
	color: #e6e6e6;
}

html section.section-secondary-scale-2 {
	background-color: #303136 !important;
	border-color: #242529 !important;
}

html section.section-secondary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #303136;
}

html section.section-tertiary {
	background-color: #2baab1 !important;
	border-color: #26969c !important;
}

html section.section-tertiary h1,
html section.section-tertiary h2,
html section.section-tertiary h3,
html section.section-tertiary h4,
html section.section-tertiary h5,
html section.section-tertiary h6 {
	color: #ffffff;
}

html section.section-tertiary p {
	color: #e6e6e6;
}

html section.section-tertiary-scale-2 {
	background-color: #218388 !important;
	border-color: #1c6f73 !important;
}

html section.section-tertiary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #218388;
}

html section.section-quaternary {
	background-color: #383f48 !important;
	border-color: #2d323a !important;
}

html section.section-quaternary h1,
html section.section-quaternary h2,
html section.section-quaternary h3,
html section.section-quaternary h4,
html section.section-quaternary h5,
html section.section-quaternary h6 {
	color: #ffffff;
}

html section.section-quaternary p {
	color: #e6e6e6;
}

html section.section-quaternary-scale-2 {
	background-color: #22262b !important;
	border-color: #17191d !important;
}

html section.section-quaternary-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #22262b;
}

html section.section-dark {
	background-color: #212529 !important;
	border-color: #16181b !important;
}

html section.section-dark h1,
html section.section-dark h2,
html section.section-dark h3,
html section.section-dark h4,
html section.section-dark h5,
html section.section-dark h6 {
	color: #ffffff;
}

html section.section-dark p {
	color: #e6e6e6;
}

html section.section-dark-scale-2 {
	background-color: #0a0c0d !important;
	border-color: #000000 !important;
}

html section.section-dark-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #0a0c0d;
}

html section.section-light {
	background-color: #ffffff !important;
	border-color: #f2f2f2 !important;
}

html section.section-light h1,
html section.section-light h2,
html section.section-light h3,
html section.section-light h4,
html section.section-light h5,
html section.section-light h6 {
	color: #777777;
}

html section.section-light p {
	color: #5e5e5e;
}

html section.section-light-scale-2 {
	background-color: #e6e6e6 !important;
	border-color: #d9d9d9 !important;
}

html section.section-light-scale-2 .sort-source.sort-source-style-2 > li.active > a:after {
	border-top-color: #e6e6e6;
}

section.section.section-background-half-primary-half-secondary {
	background: -webkit-gradient(linear, left top, right top, color-stop(50%, #47648b), color-stop(50%, #484a51));
	background: linear-gradient(to right, #47648b 50%, #484a51 50%);
}

section.section.section-overlay-opacity-gradient:before {
	background: linear-gradient(135deg, #47648b 0%, #484a51 80%);
}

html .bg-color-light-scale-1 {
	background-color: #f7f7f7 !important;
}

html .section.bg-color-light-scale-1,
html .bg-color-light-scale-border-top-1 {
	border-top-color: #efefef !important;
}

html .bg-color-light-scale-2 {
	background-color: #e8e8e8 !important;
}

html .section.bg-color-light-scale-2,
html .bg-color-light-scale-border-top-2 {
	border-top-color: #e0e0e0 !important;
}

html .bg-color-light-scale-3 {
	background-color: #e0e0e0 !important;
}

html .section.bg-color-light-scale-3,
html .bg-color-light-scale-border-top-3 {
	border-top-color: #d8d8d8 !important;
}

html .bg-color-light-scale-4 {
	background-color: #d8d8d8 !important;
}

html .section.bg-color-light-scale-4,
html .bg-color-light-scale-border-top-4 {
	border-top-color: #d1d1d1 !important;
}

html .bg-color-light-scale-5 {
	background-color: #d1d1d1 !important;
}

html .section.bg-color-light-scale-5,
html .bg-color-light-scale-border-top-5 {
	border-top-color: #c9c9c9 !important;
}

html .bg-color-light-scale-6 {
	background-color: #c9c9c9 !important;
}

html .section.bg-color-light-scale-6,
html .bg-color-light-scale-border-top-6 {
	border-top-color: #c1c1c1 !important;
}

html .bg-color-light-scale-7 {
	background-color: #c1c1c1 !important;
}

html .section.bg-color-light-scale-7,
html .bg-color-light-scale-border-top-7 {
	border-top-color: #bababa !important;
}

html .bg-color-light-scale-8 {
	background-color: #bababa !important;
}

html .section.bg-color-light-scale-8,
html .bg-color-light-scale-border-top-8 {
	border-top-color: #b2b2b2 !important;
}

html .bg-color-light-scale-9 {
	background-color: #b2b2b2 !important;
}

html .section.bg-color-light-scale-9,
html .bg-color-light-scale-border-top-9 {
	border-top-color: #ababab !important;
}

html .bg-color-dark-scale-1 {
	background-color: #212529 !important;
}

html .section.bg-color-dark-scale-1,
html .bg-color-dark-scale-border-top-1 {
	border-top-color: #1a1d21 !important;
}

html .bg-color-dark-scale-2 {
	background-color: #1c2023 !important;
}

html .section.bg-color-dark-scale-2,
html .bg-color-dark-scale-border-top-2 {
	border-top-color: #16181b !important;
}

html .bg-color-dark-scale-3 {
	background-color: #181b1e !important;
}

html .section.bg-color-dark-scale-3,
html .bg-color-dark-scale-border-top-3 {
	border-top-color: #111315 !important;
}

html .bg-color-dark-scale-4 {
	background-color: #131618 !important;
}

html .section.bg-color-dark-scale-4,
html .bg-color-dark-scale-border-top-4 {
	border-top-color: #0d0e10 !important;
}

html .bg-color-dark-scale-5 {
	background-color: #0f1112 !important;
}

html .section.bg-color-dark-scale-5,
html .bg-color-dark-scale-border-top-5 {
	border-top-color: #08090a !important;
}

html .bg-color-dark-scale-6 {
	background-color: #0a0c0d !important;
}

html .section.bg-color-dark-scale-6,
html .bg-color-dark-scale-border-top-6 {
	border-top-color: #030404 !important;
}

html .bg-color-dark-scale-7 {
	background-color: #060607 !important;
}

html .section.bg-color-dark-scale-7,
html .bg-color-dark-scale-border-top-7 {
	border-top-color: #000000 !important;
}

html .bg-color-dark-scale-8 {
	background-color: #010101 !important;
}

html .section.bg-color-dark-scale-8,
html .bg-color-dark-scale-border-top-8 {
	border-top-color: #000000 !important;
}

html .bg-color-dark-scale-9 {
	background-color: #000000 !important;
}

html .section.bg-color-dark-scale-9,
html .bg-color-dark-scale-border-top-9 {
	border-top-color: #000000 !important;
}

section.page-header .page-header-title-border {
	background-color: #47648b !important;
}

section.page-header.custom-product {
	background-color: #3e587a;
	border-top-color: #445f84;
}

html .page-header-color.page-header-primary {
	background-color: #47648b;
	border-bottom-color: #47648b;
	color: #ffffff;
}

html .page-header-color.page-header-primary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-primary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-primary a {
	color: #ffffff;
}

html .page-header-color.page-header-primary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-secondary {
	background-color: #484a51;
	border-bottom-color: #484a51;
	color: #ffffff;
}

html .page-header-color.page-header-secondary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-secondary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-secondary a {
	color: #ffffff;
}

html .page-header-color.page-header-secondary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary {
	background-color: #2baab1;
	border-bottom-color: #2baab1;
	color: #ffffff;
}

html .page-header-color.page-header-tertiary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary a {
	color: #ffffff;
}

html .page-header-color.page-header-tertiary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary {
	background-color: #383f48;
	border-bottom-color: #383f48;
	color: #ffffff;
}

html .page-header-color.page-header-quaternary h1 {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary a {
	color: #ffffff;
}

html .page-header-color.page-header-quaternary .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-dark {
	background-color: #212529;
	border-bottom-color: #212529;
	color: #ffffff;
}

html .page-header-color.page-header-dark h1 {
	color: #ffffff;
}

html .page-header-color.page-header-dark h1 span {
	color: #ffffff;
}

html .page-header-color.page-header-dark a {
	color: #ffffff;
}

html .page-header-color.page-header-dark .breadcrumb > .active {
	color: #ffffff;
}

html .page-header-color.page-header-light {
	background-color: #ffffff;
	border-bottom-color: #ffffff;
	color: #777777;
}

html .page-header-color.page-header-light h1 {
	color: #777777;
}

html .page-header-color.page-header-light h1 span {
	color: #777777;
}

html .page-header-color.page-header-light a {
	color: #777777;
}

html .page-header-color.page-header-light .breadcrumb > .active {
	color: #777777;
}

html .toggle-primary .toggle label {
	color: #47648b;
	border-left-color: #47648b;
	border-right-color: #47648b;
}

html .toggle-primary .toggle.active > label {
	background-color: #47648b;
	border-color: #47648b;
	color: #ffffff;
}

html .toggle-primary.toggle-simple .toggle > label:after {
	background-color: #47648b;
}

html .toggle-primary.toggle-minimal .toggle.active > label {
	color: #47648b;
}

html .toggle-secondary .toggle label {
	color: #484a51;
	border-left-color: #484a51;
	border-right-color: #484a51;
}

html .toggle-secondary .toggle.active > label {
	background-color: #484a51;
	border-color: #484a51;
	color: #ffffff;
}

html .toggle-secondary.toggle-simple .toggle > label:after {
	background-color: #484a51;
}

html .toggle-secondary.toggle-minimal .toggle.active > label {
	color: #484a51;
}

html .toggle-tertiary .toggle label {
	color: #2baab1;
	border-left-color: #2baab1;
	border-right-color: #2baab1;
}

html .toggle-tertiary .toggle.active > label {
	background-color: #2baab1;
	border-color: #2baab1;
	color: #ffffff;
}

html .toggle-tertiary.toggle-simple .toggle > label:after {
	background-color: #2baab1;
}

html .toggle-tertiary.toggle-minimal .toggle.active > label {
	color: #2baab1;
}

html .toggle-quaternary .toggle label {
	color: #383f48;
	border-left-color: #383f48;
	border-right-color: #383f48;
}

html .toggle-quaternary .toggle.active > label {
	background-color: #383f48;
	border-color: #383f48;
	color: #ffffff;
}

html .toggle-quaternary.toggle-simple .toggle > label:after {
	background-color: #383f48;
}

html .toggle-quaternary.toggle-minimal .toggle.active > label {
	color: #383f48;
}

html .toggle-dark .toggle label {
	color: #212529;
	border-left-color: #212529;
	border-right-color: #212529;
}

html .toggle-dark .toggle.active > label {
	background-color: #212529;
	border-color: #212529;
	color: #ffffff;
}

html .toggle-dark.toggle-simple .toggle > label:after {
	background-color: #212529;
}

html .toggle-dark.toggle-minimal .toggle.active > label {
	color: #212529;
}

html .toggle-light .toggle label {
	color: #ffffff;
	border-left-color: #ffffff;
	border-right-color: #ffffff;
}

html .toggle-light .toggle.active > label {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #777777;
}

html .toggle-light.toggle-simple .toggle > label:after {
	background-color: #ffffff;
}

html .toggle-light.toggle-minimal .toggle.active > label {
	color: #ffffff;
}

.thumb-info .thumb-info-type,
.thumb-info .thumb-info-action-icon,
.thumb-info-social-icons a,
.thumbnail .zoom,
.img-thumbnail .zoom,
.thumb-info-ribbon {
	background-color: #47648b;
}

html .thumb-info .thumb-info-action-icon-primary {
	background-color: #47648b;
}

html .thumb-info .thumb-info-action-icon-primary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-secondary {
	background-color: #484a51;
}

html .thumb-info .thumb-info-action-icon-secondary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-tertiary {
	background-color: #2baab1;
}

html .thumb-info .thumb-info-action-icon-tertiary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-quaternary {
	background-color: #383f48;
}

html .thumb-info .thumb-info-action-icon-quaternary i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-dark {
	background-color: #212529;
}

html .thumb-info .thumb-info-action-icon-dark i {
	color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-light {
	background-color: #ffffff;
}

html .thumb-info .thumb-info-action-icon-light i {
	color: #777777;
}

.thumb-info-ribbon:before {
	border-right-color: #2d4058;
	border-left-color: #2d4058;
}

.thumb-info.thumb-info-block-primary .thumb-info-wrapper:before {
	background: rgba(71, 100, 139, 0.9);
}

.inverted {
	background-color: #47648b;
}

html .inverted-primary {
	background-color: #47648b;
}

html .inverted-secondary {
	background-color: #484a51;
}

html .inverted-tertiary {
	background-color: #2baab1;
}

html .inverted-quaternary {
	background-color: #383f48;
}

html .inverted-dark {
	background-color: #212529;
}

html .inverted-light {
	background-color: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #3d5577;
}

.owl-carousel.show-nav-title .owl-nav button[class*="owl-"],
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"].hover {
	color: #47648b;
}

.owl-carousel .owl-nav button[class*="owl-"] {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
	color: #ffffff;
}

.owl-carousel .owl-nav button[class*="owl-"]:hover,
.owl-carousel .owl-nav button[class*="owl-"].hover {
	background-color: #5476a4;
	border-color: #5a7dab #5a7dab #47648b;
}

.owl-carousel .owl-nav button[class*="owl-"]:active,
.owl-carousel .owl-nav button[class*="owl-"].active {
	background-color: #3a5272;
	background-image: none;
	border-color: #364c69 #364c69 #253347;
}

.owl-carousel.nav-with-transparency:not(.nav-style-1):not(.show-nav-title) .owl-nav button[class*="owl-"] {
	background-color: rgba(71, 100, 139, 0.4);
}

.owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
	color: #47648b !important;
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
	border-color: #47648b;
}

html body .tabs .nav-tabs .nav-link,
html.dark body .tabs .nav-tabs .nav-link,
html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs li.active .nav-link,
html.dark body .tabs .nav-tabs li.active .nav-link {
	color: #47648b;
}

html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs .nav-link:focus,
html.dark body .tabs .nav-tabs .nav-link:focus,
html body .tabs .nav-tabs li.active .nav-link,
html.dark body .tabs .nav-tabs li.active .nav-link,
html body .tabs .nav-tabs.nav-justified .nav-link:hover,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:hover,
html body .tabs .nav-tabs.nav-justified .nav-link:focus,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:focus {
	border-top-color: #47648b;
}

html body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link:focus {
	border-bottom-color: #47648b;
}

html body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus {
	border-left-color: #47648b;
}

html body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus {
	border-right-color: #47648b;
}

html body .tabs.tabs-simple .nav-tabs > li.active .nav-link,
html.dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link,
html body .tabs.tabs-simple .nav-tabs > li.active .nav-link:focus,
html.dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link:focus,
html body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
html body .tabs.tabs-simple .nav-tabs > li.active .nav-link:hover,
html.dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link:hover {
	border-top-color: #47648b;
	color: #47648b;
}

html body .tabs-primary .nav-tabs li .nav-link,
html.dark body .tabs-primary .nav-tabs li .nav-link,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
	color: #47648b;
}

html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #47648b;
}

html body .tabs-primary .nav-tabs li.active .nav-link,
html.dark body .tabs-primary .nav-tabs li.active .nav-link,
html body .tabs-primary .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-primary .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li.active .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-primary .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-primary .nav-tabs li.active .nav-link:focus,
html body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #47648b;
	color: #47648b;
}

html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #47648b;
}

html body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #47648b;
}

html body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #47648b;
}

html body .tabs-secondary .nav-tabs li .nav-link,
html.dark body .tabs-secondary .nav-tabs li .nav-link,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
	color: #484a51;
}

html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #484a51;
}

html body .tabs-secondary .nav-tabs li.active .nav-link,
html.dark body .tabs-secondary .nav-tabs li.active .nav-link,
html body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-secondary .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li.active .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-secondary .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-secondary .nav-tabs li.active .nav-link:focus,
html body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #484a51;
	color: #484a51;
}

html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #484a51;
}

html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #484a51;
}

html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #484a51;
}

html body .tabs-tertiary .nav-tabs li .nav-link,
html.dark body .tabs-tertiary .nav-tabs li .nav-link,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
	color: #2baab1;
}

html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #2baab1;
}

html body .tabs-tertiary .nav-tabs li.active .nav-link,
html.dark body .tabs-tertiary .nav-tabs li.active .nav-link,
html body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-tertiary .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li.active .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-tertiary .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-tertiary .nav-tabs li.active .nav-link:focus,
html body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #2baab1;
	color: #2baab1;
}

html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #2baab1;
}

html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #2baab1;
}

html body .tabs-quaternary .nav-tabs li .nav-link,
html.dark body .tabs-quaternary .nav-tabs li .nav-link,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
html body .tabs-quaternary .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
	color: #383f48;
}

html body .tabs-quaternary .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #383f48;
}

html body .tabs-quaternary .nav-tabs li.active .nav-link,
html.dark body .tabs-quaternary .nav-tabs li.active .nav-link,
html body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-quaternary .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs li.active .nav-link:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-quaternary .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-quaternary .nav-tabs li.active .nav-link:focus,
html body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #383f48;
	color: #383f48;
}

html body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #383f48;
}

html body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #383f48;
}

html body .tabs-dark .nav-tabs li .nav-link,
html.dark body .tabs-dark .nav-tabs li .nav-link,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
	color: #212529;
}

html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #212529;
}

html body .tabs-dark .nav-tabs li.active .nav-link,
html.dark body .tabs-dark .nav-tabs li.active .nav-link,
html body .tabs-dark .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-dark .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li.active .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-dark .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-dark .nav-tabs li.active .nav-link:focus,
html body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #212529;
	color: #212529;
}

html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #212529;
}

html body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #212529;
}

html body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #212529;
}

html body .tabs-light .nav-tabs li .nav-link,
html.dark body .tabs-light .nav-tabs li .nav-link,
html body .tabs-light .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link,
html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
	color: #ffffff;
}

html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #ffffff;
}

html body .tabs-light .nav-tabs li.active .nav-link,
html.dark body .tabs-light .nav-tabs li.active .nav-link,
html body .tabs-light .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-light .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-light .nav-tabs li.active .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-light .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-light .nav-tabs li.active .nav-link:focus,
html body .tabs-light .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: #ffffff;
	color: #ffffff;
}

html body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
html body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:focus,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus {
	border-bottom-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-left li.active .nav-link,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link,
html body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:focus,
html.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:focus {
	border-left-color: #ffffff;
}

html body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-right li.active .nav-link,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link,
html body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:focus,
html.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:focus {
	border-right-color: #ffffff;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus {
	border-right-color: #47648b;
	border-left-color: transparent;
}

html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus {
	border-right-color: transparent;
	border-left-color: #47648b;
}

.list.list-icons li > [class*="fa-"]:first-child,
.list.list-icons li a:first-child > [class*="fa-"]:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .icons:first-child {
	color: #47648b;
	border-color: #47648b;
}

.list.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #47648b;
}

.list.list-ordened li:before {
	color: #47648b;
	border-color: #47648b;
}

.list.list-ordened.list-ordened-style-3 li:before {
	background-color: #47648b;
}

html .list-primary.list-icons li > [class*="fa-"]:first-child,
html .list-primary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-primary.list-icons li > .icons:first-child,
html .list-primary.list-icons li a:first-child > .icons:first-child {
	color: #47648b;
	border-color: #47648b;
}

html .list-primary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-primary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #47648b;
	color: #ffffff;
}

html .list-primary.list-ordened li:before {
	color: #47648b;
}

html .list-primary.list-ordened.list-ordened-style-3 li:before {
	background-color: #47648b;
	color: #ffffff;
}

html .list-secondary.list-icons li > [class*="fa-"]:first-child,
html .list-secondary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-secondary.list-icons li > .icons:first-child,
html .list-secondary.list-icons li a:first-child > .icons:first-child {
	color: #484a51;
	border-color: #484a51;
}

html .list-secondary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #484a51;
	color: #ffffff;
}

html .list-secondary.list-ordened li:before {
	color: #484a51;
}

html .list-secondary.list-ordened.list-ordened-style-3 li:before {
	background-color: #484a51;
	color: #ffffff;
}

html .list-tertiary.list-icons li > [class*="fa-"]:first-child,
html .list-tertiary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-tertiary.list-icons li > .icons:first-child,
html .list-tertiary.list-icons li a:first-child > .icons:first-child {
	color: #2baab1;
	border-color: #2baab1;
}

html .list-tertiary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #2baab1;
	color: #ffffff;
}

html .list-tertiary.list-ordened li:before {
	color: #2baab1;
}

html .list-tertiary.list-ordened.list-ordened-style-3 li:before {
	background-color: #2baab1;
	color: #ffffff;
}

html .list-quaternary.list-icons li > [class*="fa-"]:first-child,
html .list-quaternary.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-quaternary.list-icons li > .icons:first-child,
html .list-quaternary.list-icons li a:first-child > .icons:first-child {
	color: #383f48;
	border-color: #383f48;
}

html .list-quaternary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #383f48;
	color: #ffffff;
}

html .list-quaternary.list-ordened li:before {
	color: #383f48;
}

html .list-quaternary.list-ordened.list-ordened-style-3 li:before {
	background-color: #383f48;
	color: #ffffff;
}

html .list-dark.list-icons li > [class*="fa-"]:first-child,
html .list-dark.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-dark.list-icons li > .icons:first-child,
html .list-dark.list-icons li a:first-child > .icons:first-child {
	color: #212529;
	border-color: #212529;
}

html .list-dark.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-dark.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #212529;
	color: #ffffff;
}

html .list-dark.list-ordened li:before {
	color: #212529;
}

html .list-dark.list-ordened.list-ordened-style-3 li:before {
	background-color: #212529;
	color: #ffffff;
}

html .list-light.list-icons li > [class*="fa-"]:first-child,
html .list-light.list-icons li a:first-child > [class*="fa-"]:first-child,
html .list-light.list-icons li > .icons:first-child,
html .list-light.list-icons li a:first-child > .icons:first-child {
	color: #ffffff;
	border-color: #ffffff;
}

html .list-light.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
html .list-light.list-icons.list-icons-style-3 li > .icons:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	background-color: #ffffff;
	color: #777777;
}

html .list-light.list-ordened li:before {
	color: #ffffff;
}

html .list-light.list-ordened.list-ordened-style-3 li:before {
	background-color: #ffffff;
	color: #777777;
}

.parallax blockquote i.fa-quote-left {
	color: #47648b;
}

section.video blockquote i.fa-quote-left {
	color: #47648b;
}

.accordion .card-header a {
	color: #47648b;
}

html .accordion.accordion-primary .card-header {
	background-color: #47648b !important;
}

html .accordion.accordion-primary .card-header a {
	color: #ffffff;
}

html .accordion.accordion-secondary .card-header {
	background-color: #484a51 !important;
}

html .accordion.accordion-secondary .card-header a {
	color: #ffffff;
}

html .accordion.accordion-tertiary .card-header {
	background-color: #2baab1 !important;
}

html .accordion.accordion-tertiary .card-header a {
	color: #ffffff;
}

html .accordion.accordion-quaternary .card-header {
	background-color: #383f48 !important;
}

html .accordion.accordion-quaternary .card-header a {
	color: #ffffff;
}

html .accordion.accordion-dark .card-header {
	background-color: #212529 !important;
}

html .accordion.accordion-dark .card-header a {
	color: #ffffff;
}

html .accordion.accordion-light .card-header {
	background-color: #ffffff !important;
}

html .accordion.accordion-light .card-header a {
	color: #777777;
}

html .divider.divider-primary [class*="fa-"],
html .divider.divider-primary .icons {
	color: #47648b;
}

html .divider.divider-primary.divider-small hr {
	background: #47648b;
}

html .divider.divider-secondary [class*="fa-"],
html .divider.divider-secondary .icons {
	color: #484a51;
}

html .divider.divider-secondary.divider-small hr {
	background: #484a51;
}

html .divider.divider-tertiary [class*="fa-"],
html .divider.divider-tertiary .icons {
	color: #2baab1;
}

html .divider.divider-tertiary.divider-small hr {
	background: #2baab1;
}

html .divider.divider-quaternary [class*="fa-"],
html .divider.divider-quaternary .icons {
	color: #383f48;
}

html .divider.divider-quaternary.divider-small hr {
	background: #383f48;
}

html .divider.divider-dark [class*="fa-"],
html .divider.divider-dark .icons {
	color: #212529;
}

html .divider.divider-dark.divider-small hr {
	background: #212529;
}

html .divider.divider-light [class*="fa-"],
html .divider.divider-light .icons {
	color: #ffffff;
}

html .divider.divider-light.divider-small hr {
	background: #ffffff;
}

html .divider.divider-style-2.divider-primary [class*="fa-"],
html .divider.divider-style-2.divider-primary .icons {
	background: #47648b;
	color: #ffffff;
}

html .divider.divider-style-2.divider-secondary [class*="fa-"],
html .divider.divider-style-2.divider-secondary .icons {
	background: #484a51;
	color: #ffffff;
}

html .divider.divider-style-2.divider-tertiary [class*="fa-"],
html .divider.divider-style-2.divider-tertiary .icons {
	background: #2baab1;
	color: #ffffff;
}

html .divider.divider-style-2.divider-quaternary [class*="fa-"],
html .divider.divider-style-2.divider-quaternary .icons {
	background: #383f48;
	color: #ffffff;
}

html .divider.divider-style-2.divider-dark [class*="fa-"],
html .divider.divider-style-2.divider-dark .icons {
	background: #212529;
	color: #ffffff;
}

html .divider.divider-style-2.divider-light [class*="fa-"],
html .divider.divider-style-2.divider-light .icons {
	background: #ffffff;
	color: #777777;
}

html .divider.divider-style-3.divider-primary [class*="fa-"],
html .divider.divider-style-3.divider-primary .icons {
	border-color: #47648b;
}

html .divider.divider-style-3.divider-secondary [class*="fa-"],
html .divider.divider-style-3.divider-secondary .icons {
	border-color: #484a51;
}

html .divider.divider-style-3.divider-tertiary [class*="fa-"],
html .divider.divider-style-3.divider-tertiary .icons {
	border-color: #2baab1;
}

html .divider.divider-style-3.divider-quaternary [class*="fa-"],
html .divider.divider-style-3.divider-quaternary .icons {
	border-color: #383f48;
}

html .divider.divider-style-3.divider-dark [class*="fa-"],
html .divider.divider-style-3.divider-dark .icons {
	border-color: #212529;
}

html .divider.divider-style-3.divider-light [class*="fa-"],
html .divider.divider-style-3.divider-light .icons {
	border-color: #ffffff;
}

#header .tip {
	background: #47648b;
	color: #ffffff;
}

#header .tip:before {
	border-right-color: #47648b;
	border-left-color: transparent;
}

html #header .tip-primary {
	background: #47648b !important;
	color: #ffffff !important;
}

html #header .tip-primary:before {
	border-right-color: #47648b !important;
}

html #header .tip-secondary {
	background: #484a51 !important;
	color: #ffffff !important;
}

html #header .tip-secondary:before {
	border-right-color: #484a51 !important;
}

html #header .tip-tertiary {
	background: #2baab1 !important;
	color: #ffffff !important;
}

html #header .tip-tertiary:before {
	border-right-color: #2baab1 !important;
}

html #header .tip-quaternary {
	background: #383f48 !important;
	color: #ffffff !important;
}

html #header .tip-quaternary:before {
	border-right-color: #383f48 !important;
}

html #header .tip-dark {
	background: #212529 !important;
	color: #ffffff !important;
}

html #header .tip-dark:before {
	border-right-color: #212529 !important;
}

html #header .tip-light {
	background: #ffffff !important;
	color: #777777 !important;
}

html #header .tip-light:before {
	border-right-color: #ffffff !important;
}

html[dir="rtl"] #header .tip:before {
	border-left-color: #47648b;
}

html[dir="rtl"] #header .tip.skin:before {
	border-left-color: #47648b;
}

html[dir="rtl"] #header .tip-primary:before {
	border-left-color: #47648b !important;
	border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-secondary:before {
	border-left-color: #484a51 !important;
	border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-tertiary:before {
	border-left-color: #2baab1 !important;
	border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-quaternary:before {
	border-left-color: #383f48 !important;
	border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-dark:before {
	border-left-color: #212529 !important;
	border-right-color: transparent !important;
}

html[dir="rtl"] #header .tip-light:before {
	border-left-color: #ffffff !important;
	border-right-color: transparent !important;
}

#header .header-btn-collapse-nav {
	background: #47648b;
}

@media (min-width: 992px) {
	#header .header-nav-main nav > ul > li > a {
		color: #47648b;
	}

	#header .header-nav-main nav > ul > li.open > a,
	#header .header-nav-main nav > ul > li:hover > a {
		background: #47648b;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
		border-top-color: #47648b;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > a:before,
	#header .header-nav-main nav > ul > li.dropdown:hover > a:before {
		border-bottom-color: #47648b;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
		border-top-color: #47648b;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu li a {
		color: #47648b;
	}

	#header .header-nav-main nav > ul:not(:hover) > li > a.active {
		background-color: #47648b;
		color: #ffffff;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:hover > a,
	#header .header-nav.header-nav-stripe nav > ul > li.open > a {
		background-color: #47648b;
	}

	#header .header-nav.header-nav-stripe nav > ul:not(:hover) > li > a.active {
		background-color: #47648b;
	}

	#header .header-nav.header-nav-stripe nav > ul:hover > li > a.active {
		color: #47648b;
	}

	#header .header-nav.header-nav-links nav > ul li:hover > a {
		color: #47648b;
	}

	#header .header-nav.header-nav-links nav > ul:not(:hover) > li > a.active {
		color: #47648b;
	}

	#header .header-nav.header-nav-links.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar-thumb {
		background: #47648b !important;
	}

	#header .header-nav.header-nav-line nav > ul li:hover > a {
		color: #47648b;
	}

	#header .header-nav.header-nav-line nav > ul li:hover > a:before {
		background: #47648b;
	}

	#header .header-nav.header-nav-line nav > ul:not(:hover) li > a.active {
		color: #47648b;
	}

	#header .header-nav.header-nav-line nav > ul:not(:hover) li > a.active:before {
		background: #47648b;
	}

	#header .header-nav-main.header-nav-main-slide nav > ul > li.open > span > a,
	#header .header-nav-main.header-nav-main-slide nav > ul > li:hover > span > a {
		color: #47648b;
	}

	#header .header-nav-main.header-nav-main-slide nav > ul:not(:hover) > li > span > a.active {
		color: #47648b;
	}

	html.side-header #header.side-header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
	html.side-header #header.side-header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
		border-left-color: #47648b;
	}

	html.side-header-right #header.side-header .header-nav-main nav > ul li.dropdown.open > .dropdown-menu,
	html.side-header-right #header.side-header .header-nav-main nav > ul li.dropdown:hover > .dropdown-menu {
		border-right-color: #47648b;
	}
}

@media (min-width: 992px) {
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu {
		background-color: #47648b;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #425d81;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li:hover > a {
		background: #4c6b95;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:before {
		border-bottom-color: #47648b;
		background: #47648b;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-primary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
		background: #47648b;
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
		background: #47648b;
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover {
		color: #47648b;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover:before {
		background-color: #47648b;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
		color: #47648b;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:before {
		background: #47648b;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
		color: #47648b;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active:before {
		background-color: #47648b;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a {
		color: #47648b;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary a:hover {
		color: #47648b;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a {
		color: #47648b;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-primary > a.active {
		color: #47648b;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu {
		background-color: #484a51;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #414349;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li:hover > a {
		background: #4f5159;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a:before {
		border-bottom-color: #484a51;
		background: #484a51;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-secondary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
		background: #484a51;
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
		background: #484a51;
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover {
		color: #484a51;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover:before {
		background-color: #484a51;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
		color: #484a51;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a:before {
		background: #484a51;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
		color: #484a51;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active:before {
		background-color: #484a51;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a {
		color: #484a51;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary a:hover {
		color: #484a51;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary:hover > a {
		color: #484a51;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-secondary > a.active {
		color: #484a51;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu {
		background-color: #2baab1;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #289ea5;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li:hover > a {
		background: #2eb6bd;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a:before {
		border-bottom-color: #2baab1;
		background: #2baab1;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-tertiary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
		background: #2baab1;
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
		background: #2baab1;
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover {
		color: #2baab1;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover:before {
		background-color: #2baab1;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
		color: #2baab1;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a:before {
		background: #2baab1;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
		color: #2baab1;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active:before {
		background-color: #2baab1;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a {
		color: #2baab1;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary a:hover {
		color: #2baab1;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary:hover > a {
		color: #2baab1;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-tertiary > a.active {
		color: #2baab1;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu {
		background-color: #383f48;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #31373f;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li:hover > a {
		background: #3f4751;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a:before {
		border-bottom-color: #383f48;
		background: #383f48;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-quaternary .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a {
		background: #383f48;
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary .dropdown-menu li a {
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-quaternary > a.active {
		background: #383f48;
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:hover {
		color: #383f48;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:hover:before {
		background-color: #383f48;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a {
		color: #383f48;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a:before {
		background: #383f48;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-quaternary > a.active {
		color: #383f48;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-quaternary > a.active:before {
		background-color: #383f48;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a {
		color: #383f48;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary a:hover {
		color: #383f48;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary:hover > a {
		color: #383f48;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-quaternary > a.active {
		color: #383f48;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu {
		background-color: #212529;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
		color: #ffffff;
		border-bottom-color: #1a1d21;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li:hover > a {
		background: #282d31;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a:before {
		border-bottom-color: #212529;
		background: #212529;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-dark .dropdown-mega-sub-title {
		color: #ffffff;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
		background: #212529;
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
		color: #ffffff;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
		background: #212529;
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover {
		color: #212529;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover:before {
		background-color: #212529;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
		color: #212529;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a:before {
		background: #212529;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
		color: #212529;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active:before {
		background-color: #212529;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a {
		color: #212529;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark a:hover {
		color: #212529;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-dark:hover > a {
		color: #212529;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-dark > a.active {
		color: #212529;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu {
		background-color: #ffffff;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li a {
		color: #777777;
		border-bottom-color: #f7f7f7;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li:hover > a {
		background: #ffffff;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.open > a:before,
	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a:before {
		border-bottom-color: #ffffff;
		background: #ffffff;
	}

	html #header .header-nav .header-nav-main nav > ul > li.dropdown-full-color.dropdown-mega.dropdown-light .dropdown-mega-sub-title {
		color: #777777;
		opacity: 0.70;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
		background: #ffffff;
		color: #777777;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light .dropdown-menu li a {
		color: #777777;
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
		background: #ffffff;
		color: #777777;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover {
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover:before {
		background-color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light.open > a:before,
	html #header .header-nav-line .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a:before {
		background: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
		color: #ffffff;
	}

	html #header .header-nav-line .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active:before {
		background-color: #ffffff;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a {
		color: #ffffff;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:focus,
	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light a:hover {
		color: #ffffff;
	}

	html #header .header-nav-links .header-nav-main nav > ul > li.dropdown-full-color.dropdown-light:hover > a {
		color: #ffffff;
	}

	html #header .header-nav-links .header-nav-main nav > ul:not(:hover) > li.dropdown-full-color.dropdown-light > a.active {
		color: #ffffff;
	}
}

@media (min-width: 992px) {
	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul > li:hover > a,
	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul > li .wrapper-items-cloned:hover > a {
		color: #47648b !important;
	}

	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul:not(:hover) > li > a.active,
	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav > ul:not(:hover) > li .wrapper-items-cloned > a.active {
		color: #47648b !important;
	}
}

@media (max-width: 991px) {
	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a {
		color: #47648b;
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a:active {
		color: #47648b;
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active {
		background: #47648b;
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active:focus,
	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active:hover {
		background: #47648b;
	}

	#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
		background: #47648b;
	}

	#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:focus,
	#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:hover {
		background: #47648b;
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
		color: #47648b !important;
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav::-webkit-scrollbar-thumb {
		background-color: #47648b;
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li > a.active {
		color: #47648b !important;
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav > ul > li:hover > a {
		color: #47648b !important;
	}
}

@media (max-width: 991px) {
	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a {
		color: #47648b;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a.active {
		background: #47648b !important;
		color: #ffffff !important;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary > a {
		color: #484a51;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-secondary > a.active {
		background: #484a51 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary > a {
		color: #2baab1;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-tertiary > a.active {
		background: #2baab1 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary > a {
		color: #383f48;
	}

	html #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-quaternary > a.active {
		background: #383f48 !important;
		color: #ffffff !important;
	}
}

html #header .header-top.header-top-primary {
	background: #47648b;
	border-top-color: #3e587a;
}

html #header .header-top.header-top-primary .header-nav-top ul > li.open > a {
	background: #50709c;
}

html #header .header-top.header-top-primary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-primary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-primary .header-nav-top ul > li.open > a:focus {
	background: #50709c;
}

html #header .header-top.header-top-primary .dropdown-menu {
	background: #50709c;
	border-color: #50709c;
}

html #header .header-top.header-top-primary .dropdown-menu a:hover,
html #header .header-top.header-top-primary .dropdown-menu a:focus {
	background: #5a7dab !important;
}

html #header .header-top.header-top-secondary {
	background: #484a51;
	border-top-color: #3c3e44;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a {
	background: #54565e;
}

html #header .header-top.header-top-secondary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-secondary .header-nav-top ul > li.open > a:focus {
	background: #54565e;
}

html #header .header-top.header-top-secondary .dropdown-menu {
	background: #54565e;
	border-color: #54565e;
}

html #header .header-top.header-top-secondary .dropdown-menu a:hover,
html #header .header-top.header-top-secondary .dropdown-menu a:focus {
	background: #60636c !important;
}

html #header .header-top.header-top-tertiary {
	background: #2baab1;
	border-top-color: #26969c;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a {
	background: #30bec6;
}

html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-tertiary .header-nav-top ul > li.open > a:focus {
	background: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu {
	background: #30bec6;
	border-color: #30bec6;
}

html #header .header-top.header-top-tertiary .dropdown-menu a:hover,
html #header .header-top.header-top-tertiary .dropdown-menu a:focus {
	background: #3fc9d0 !important;
}

html #header .header-top.header-top-quaternary {
	background: #383f48;
	border-top-color: #2d323a;
}

html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a {
	background: #434c56;
}

html #header .header-top.header-top-quaternary .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-quaternary .header-nav-top ul > li.open > a:focus {
	background: #434c56;
}

html #header .header-top.header-top-quaternary .dropdown-menu {
	background: #434c56;
	border-color: #434c56;
}

html #header .header-top.header-top-quaternary .dropdown-menu a:hover,
html #header .header-top.header-top-quaternary .dropdown-menu a:focus {
	background: #4e5865 !important;
}

html #header .header-top.header-top-dark {
	background: #212529;
	border-top-color: #16181b;
}

html #header .header-top.header-top-dark .header-nav-top ul > li.open > a {
	background: #2c3237;
}

html #header .header-top.header-top-dark .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-dark .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-dark .header-nav-top ul > li.open > a:focus {
	background: #2c3237;
}

html #header .header-top.header-top-dark .dropdown-menu {
	background: #2c3237;
	border-color: #2c3237;
}

html #header .header-top.header-top-dark .dropdown-menu a:hover,
html #header .header-top.header-top-dark .dropdown-menu a:focus {
	background: #383f45 !important;
}

html #header .header-top.header-top-light {
	background: #ffffff;
	border-top-color: #f2f2f2;
}

html #header .header-top.header-top-light .header-nav-top ul > li.open > a {
	background: #ffffff;
}

html #header .header-top.header-top-light .header-nav-top ul > li > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:hover,
html #header .header-top.header-top-light .header-nav-top ul > li > a:focus,
html #header .header-top.header-top-light .header-nav-top ul > li.open > a:focus {
	background: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu {
	background: #ffffff;
	border-color: #ffffff;
}

html #header .header-top.header-top-light .dropdown-menu a:hover,
html #header .header-top.header-top-light .dropdown-menu a:focus {
	background: #ffffff !important;
}

@media (min-width: 992px) {
	html #header .header-nav-bar-primary {
		background: #47648b !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-secondary {
		background: #484a51 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-tertiary {
		background: #2baab1 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-quaternary {
		background: #383f48 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-dark {
		background: #212529 !important;
		color: #ffffff !important;
	}

	html #header .header-nav-bar-light {
		background: #ffffff !important;
		color: #777777 !important;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a,
	#header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
		color: #47648b;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li > a.active {
		color: #47648b;
	}
}

#header .header-nav-features .header-nav-features-search-reveal-big-search .form-control {
	border-bottom-color: #0088cc;
}

.testimonial blockquote {
	background: #50709c;
}

.testimonial .testimonial-arrow-down {
	border-top-color: #50709c;
}

html .testimonial-primary blockquote {
	background: #50709c;
}

html .testimonial-primary .testimonial-arrow-down {
	border-top-color: #50709c;
}

html .testimonial-primary.testimonial-style-7 {
	background: #50709c;
}

html .testimonial-quotes-primary blockquote:before,
html .testimonial-quotes-primary blockquote:after {
	color: #47648b !important;
}

html .testimonial-secondary blockquote {
	background: #54565e;
}

html .testimonial-secondary .testimonial-arrow-down {
	border-top-color: #54565e;
}

html .testimonial-secondary.testimonial-style-7 {
	background: #54565e;
}

html .testimonial-quotes-secondary blockquote:before,
html .testimonial-quotes-secondary blockquote:after {
	color: #47648b !important;
}

html .testimonial-tertiary blockquote {
	background: #30bec6;
}

html .testimonial-tertiary .testimonial-arrow-down {
	border-top-color: #30bec6;
}

html .testimonial-tertiary.testimonial-style-7 {
	background: #30bec6;
}

html .testimonial-quotes-tertiary blockquote:before,
html .testimonial-quotes-tertiary blockquote:after {
	color: #47648b !important;
}

html .testimonial-quaternary blockquote {
	background: #434c56;
}

html .testimonial-quaternary .testimonial-arrow-down {
	border-top-color: #434c56;
}

html .testimonial-quaternary.testimonial-style-7 {
	background: #434c56;
}

html .testimonial-quotes-quaternary blockquote:before,
html .testimonial-quotes-quaternary blockquote:after {
	color: #47648b !important;
}

html .testimonial-dark blockquote {
	background: #2c3237;
}

html .testimonial-dark .testimonial-arrow-down {
	border-top-color: #2c3237;
}

html .testimonial-dark.testimonial-style-7 {
	background: #2c3237;
}

html .testimonial-quotes-dark blockquote:before,
html .testimonial-quotes-dark blockquote:after {
	color: #47648b !important;
}

html .testimonial-light blockquote {
	background: #ffffff;
}

html .testimonial-light .testimonial-arrow-down {
	border-top-color: #ffffff;
}

html .testimonial-light.testimonial-style-7 {
	background: #ffffff;
}

html .testimonial-quotes-light blockquote:before,
html .testimonial-quotes-light blockquote:after {
	color: #47648b !important;
}

.circular-bar.only-icon [class*="fa-"],
.circular-bar.only-icon .icons {
	color: #47648b;
}

html .counters .counter-primary [class*="fa-"],
html .counters .counter-primary .icons,
html .counters .counter-primary strong {
	color: #47648b;
}

html .counters .counter-secondary [class*="fa-"],
html .counters .counter-secondary .icons,
html .counters .counter-secondary strong {
	color: #484a51;
}

html .counters .counter-tertiary [class*="fa-"],
html .counters .counter-tertiary .icons,
html .counters .counter-tertiary strong {
	color: #2baab1;
}

html .counters .counter-quaternary [class*="fa-"],
html .counters .counter-quaternary .icons,
html .counters .counter-quaternary strong {
	color: #383f48;
}

html .counters .counter-dark [class*="fa-"],
html .counters .counter-dark .icons,
html .counters .counter-dark strong {
	color: #212529;
}

html .counters .counter-light [class*="fa-"],
html .counters .counter-light .icons,
html .counters .counter-light strong {
	color: #ffffff;
}

html .countdown.countdown-primary > span > span {
	color: #47648b;
}

html .countdown.countdown-secondary > span > span {
	color: #484a51;
}

html .countdown.countdown-tertiary > span > span {
	color: #2baab1;
}

html .countdown.countdown-quaternary > span > span {
	color: #383f48;
}

html .countdown.countdown-dark > span > span {
	color: #212529;
}

html .countdown.countdown-light > span > span {
	color: #ffffff;
}

.icon-featured {
	background-color: #47648b;
}

html .featured-box-primary .icon-featured {
	background-color: #47648b;
}

html .featured-box-primary h4 {
	color: #47648b;
}

html .featured-box-primary .box-content {
	border-top-color: #47648b;
}

html .featured-box-primary .box-content-border-bottom {
	border-bottom-color: #47648b;
}

html .featured-box-secondary .icon-featured {
	background-color: #484a51;
}

html .featured-box-secondary h4 {
	color: #484a51;
}

html .featured-box-secondary .box-content {
	border-top-color: #484a51;
}

html .featured-box-secondary .box-content-border-bottom {
	border-bottom-color: #484a51;
}

html .featured-box-tertiary .icon-featured {
	background-color: #2baab1;
}

html .featured-box-tertiary h4 {
	color: #2baab1;
}

html .featured-box-tertiary .box-content {
	border-top-color: #2baab1;
}

html .featured-box-tertiary .box-content-border-bottom {
	border-bottom-color: #2baab1;
}

html .featured-box-quaternary .icon-featured {
	background-color: #383f48;
}

html .featured-box-quaternary h4 {
	color: #383f48;
}

html .featured-box-quaternary .box-content {
	border-top-color: #383f48;
}

html .featured-box-quaternary .box-content-border-bottom {
	border-bottom-color: #383f48;
}

html .featured-box-dark .icon-featured {
	background-color: #212529;
}

html .featured-box-dark h4 {
	color: #212529;
}

html .featured-box-dark .box-content {
	border-top-color: #212529;
}

html .featured-box-dark .box-content-border-bottom {
	border-bottom-color: #212529;
}

html .featured-box-light .icon-featured {
	background-color: #ffffff;
}

html .featured-box-light h4 {
	color: #ffffff;
}

html .featured-box-light .box-content {
	border-top-color: #ffffff;
}

html .featured-box-light .box-content-border-bottom {
	border-bottom-color: #ffffff;
}

html .featured-boxes-style-3 .featured-box.featured-box-primary .icon-featured {
	border-color: #47648b;
	color: #47648b;
}

html .featured-boxes-style-3 .featured-box.featured-box-secondary .icon-featured {
	border-color: #484a51;
	color: #484a51;
}

html .featured-boxes-style-3 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1;
}

html .featured-boxes-style-3 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48;
}

html .featured-boxes-style-3 .featured-box.featured-box-dark .icon-featured {
	border-color: #212529;
	color: #212529;
}

html .featured-boxes-style-3 .featured-box.featured-box-light .icon-featured {
	border-color: #ffffff;
	color: #ffffff;
}

html .featured-boxes-style-4 .featured-box.featured-box-primary .icon-featured {
	border-color: #47648b;
	color: #47648b;
}

html .featured-boxes-style-4 .featured-box.featured-box-secondary .icon-featured {
	border-color: #484a51;
	color: #484a51;
}

html .featured-boxes-style-4 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1;
}

html .featured-boxes-style-4 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48;
}

html .featured-boxes-style-4 .featured-box.featured-box-dark .icon-featured {
	border-color: #212529;
	color: #212529;
}

html .featured-boxes-style-4 .featured-box.featured-box-light .icon-featured {
	border-color: #ffffff;
	color: #ffffff;
}

html .featured-boxes-style-5 .featured-box.featured-box-primary .icon-featured {
	color: #47648b;
}

html .featured-boxes-style-5 .featured-box.featured-box-secondary .icon-featured {
	color: #484a51;
}

html .featured-boxes-style-5 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-5 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-5 .featured-box.featured-box-dark .icon-featured {
	color: #212529;
}

html .featured-boxes-style-5 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

html .featured-boxes-style-6 .featured-box.featured-box-primary .icon-featured {
	color: #47648b;
}

html .featured-boxes-style-6 .featured-box.featured-box-secondary .icon-featured {
	color: #484a51;
}

html .featured-boxes-style-6 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-6 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-6 .featured-box.featured-box-dark .icon-featured {
	color: #212529;
}

html .featured-boxes-style-6 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

html .featured-boxes-style-8 .featured-box.featured-box-primary .icon-featured {
	color: #47648b;
}

html .featured-boxes-style-8 .featured-box.featured-box-secondary .icon-featured {
	color: #484a51;
}

html .featured-boxes-style-8 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1;
}

html .featured-boxes-style-8 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48;
}

html .featured-boxes-style-8 .featured-box.featured-box-dark .icon-featured {
	color: #212529;
}

html .featured-boxes-style-8 .featured-box.featured-box-light .icon-featured {
	color: #ffffff;
}

.featured-boxes-modern-style-primary .featured-box {
	background: #47648b;
	background: linear-gradient(135deg, #47648b 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#47648b', endColorstr='#383f48', GradientType=1);
}

.featured-boxes-modern-style-secondary .featured-box {
	background: #484a51;
	background: linear-gradient(135deg, #484a51 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#484a51', endColorstr='#383f48', GradientType=1);
}

.featured-boxes-modern-style-tertiary .featured-box {
	background: #2baab1;
	background: linear-gradient(135deg, #2baab1 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2baab1', endColorstr='#383f48', GradientType=1);
}

html .featured-box-effect-2.featured-box-primary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #47648b;
	box-shadow: 0 0 0 3px #47648b;
}

html .featured-box-effect-2.featured-box-secondary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #484a51;
	box-shadow: 0 0 0 3px #484a51;
}

html .featured-box-effect-2.featured-box-tertiary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #2baab1;
	box-shadow: 0 0 0 3px #2baab1;
}

html .featured-box-effect-2.featured-box-quaternary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #383f48;
	box-shadow: 0 0 0 3px #383f48;
}

html .featured-box-effect-2.featured-box-dark .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #212529;
	box-shadow: 0 0 0 3px #212529;
}

html .featured-box-effect-2.featured-box-light .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #ffffff;
	box-shadow: 0 0 0 3px #ffffff;
}

html .featured-box-effect-3.featured-box-primary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #47648b;
	box-shadow: 0 0 0 10px #47648b;
}

html .featured-box-effect-3.featured-box-primary:hover .icon-featured {
	background: #47648b !important;
}

html .featured-box-effect-3.featured-box-secondary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #484a51;
	box-shadow: 0 0 0 10px #484a51;
}

html .featured-box-effect-3.featured-box-secondary:hover .icon-featured {
	background: #484a51 !important;
}

html .featured-box-effect-3.featured-box-tertiary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #2baab1;
	box-shadow: 0 0 0 10px #2baab1;
}

html .featured-box-effect-3.featured-box-tertiary:hover .icon-featured {
	background: #2baab1 !important;
}

html .featured-box-effect-3.featured-box-quaternary .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #383f48;
	box-shadow: 0 0 0 10px #383f48;
}

html .featured-box-effect-3.featured-box-quaternary:hover .icon-featured {
	background: #383f48 !important;
}

html .featured-box-effect-3.featured-box-dark .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #212529;
	box-shadow: 0 0 0 10px #212529;
}

html .featured-box-effect-3.featured-box-dark:hover .icon-featured {
	background: #212529 !important;
}

html .featured-box-effect-3.featured-box-light .icon-featured:after {
	-webkit-box-shadow: 0 0 0 10px #ffffff;
	box-shadow: 0 0 0 10px #ffffff;
}

html .featured-box-effect-3.featured-box-light:hover .icon-featured {
	background: #ffffff !important;
}

.feature-box .feature-box-icon {
	background-color: #47648b;
}

html .feature-box-primary .feature-box-icon {
	background-color: #47648b;
}

html .feature-box-secondary .feature-box-icon {
	background-color: #484a51;
}

html .feature-box-tertiary .feature-box-icon {
	background-color: #2baab1;
}

html .feature-box-quaternary .feature-box-icon {
	background-color: #383f48;
}

html .feature-box-dark .feature-box-icon {
	background-color: #212529;
}

html .feature-box-light .feature-box-icon {
	background-color: #ffffff;
}

.feature-box.feature-box-style-2 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-primary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-2 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-secondary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-2 .feature-box-icon .icons {
	color: #484a51;
}

html .feature-box-tertiary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-2 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-quaternary.feature-box-style-2 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-2 .feature-box-icon .icons {
	color: #212529;
}

html .feature-box-light.feature-box-style-2 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-2 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-3 .feature-box-icon {
	border-color: #47648b;
}

.feature-box.feature-box-style-3 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-3 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon {
	border-color: #47648b;
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-3 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon {
	border-color: #484a51;
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-3 .feature-box-icon .icons {
	color: #484a51;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon {
	border-color: #2baab1;
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-3 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon {
	border-color: #383f48;
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-quaternary.feature-box-style-3 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon {
	border-color: #212529;
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-3 .feature-box-icon .icons {
	color: #212529;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon {
	border-color: #ffffff;
}

html .feature-box-light.feature-box-style-3 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-3 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-4 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-4 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-primary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-4 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-secondary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-4 .feature-box-icon .icons {
	color: #484a51;
}

html .feature-box-tertiary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-4 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-quaternary.feature-box-style-4 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-4 .feature-box-icon .icons {
	color: #212529;
}

html .feature-box-light.feature-box-style-4 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-4 .feature-box-icon .icons {
	color: #ffffff;
}

.feature-box.feature-box-style-5 .feature-box-icon [class*="fa-"],
.feature-box.feature-box-style-5 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-primary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-5 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-secondary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-5 .feature-box-icon .icons {
	color: #484a51;
}

html .feature-box-tertiary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-5 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-quaternary.feature-box-style-5 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-5 .feature-box-icon .icons {
	color: #212529;
}

html .feature-box-light.feature-box-style-5 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-5 .feature-box-icon .icons {
	color: #ffffff;
}

html .feature-box-primary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-primary.feature-box-style-6 .feature-box-icon .icons {
	color: #47648b;
}

html .feature-box-secondary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-secondary.feature-box-style-6 .feature-box-icon .icons {
	color: #484a51;
}

html .feature-box-tertiary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-tertiary.feature-box-style-6 .feature-box-icon .icons {
	color: #2baab1;
}

html .feature-box-quaternary.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-quaternary.feature-box-style-6 .feature-box-icon .icons {
	color: #383f48;
}

html .feature-box-dark.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-dark.feature-box-style-6 .feature-box-icon .icons {
	color: #212529;
}

html .feature-box-light.feature-box-style-6 .feature-box-icon [class*="fa-"],
html .feature-box-light.feature-box-style-6 .feature-box-icon .icons {
	color: #ffffff;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(1) {
	background-color: #364c69;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(2) {
	background-color: #3e587a;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(3) {
	background-color: #47648b;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(4) {
	background-color: #50709c;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(5) {
	background-color: #5a7dab;
}

html .featured-boxes-full-scale > .featured-box-full-primary:nth-child(6) {
	background-color: #6b8ab3;
}

html .featured-boxes-full .featured-box-full-primary {
	background-color: #47648b;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-secondary {
	background-color: #484a51;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-tertiary {
	background-color: #2baab1;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-quaternary {
	background-color: #383f48;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-dark {
	background-color: #212529;
	color: #ffffff;
}

html .featured-boxes-full .featured-box-full-light {
	background-color: #ffffff;
	color: #777777;
}

.pricing-table .most-popular {
	border-color: #47648b;
}

.pricing-table .most-popular h3 {
	background-color: #47648b;
}

.pricing-table.princig-table-flat .plan h3 {
	background-color: #47648b;
}

.pricing-table.princig-table-flat .plan h3 span {
	background: #47648b;
}

html .call-to-action.featured.featured-primary {
	border-top-color: #47648b;
}

html .call-to-action.call-to-action-primary {
	background: #47648b;
}

html .call-to-action.with-full-borders-primary {
	border-color: #47648b;
}

html .call-to-action.call-to-action-primary {
	background: #47648b;
}

html .call-to-action.call-to-action-primary h3,
html .call-to-action.call-to-action-primary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-secondary {
	border-top-color: #484a51;
}

html .call-to-action.call-to-action-secondary {
	background: #484a51;
}

html .call-to-action.with-full-borders-secondary {
	border-color: #484a51;
}

html .call-to-action.call-to-action-secondary {
	background: #484a51;
}

html .call-to-action.call-to-action-secondary h3,
html .call-to-action.call-to-action-secondary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-tertiary {
	border-top-color: #2baab1;
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1;
}

html .call-to-action.with-full-borders-tertiary {
	border-color: #2baab1;
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1;
}

html .call-to-action.call-to-action-tertiary h3,
html .call-to-action.call-to-action-tertiary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-quaternary {
	border-top-color: #383f48;
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48;
}

html .call-to-action.with-full-borders-quaternary {
	border-color: #383f48;
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48;
}

html .call-to-action.call-to-action-quaternary h3,
html .call-to-action.call-to-action-quaternary p {
	color: #ffffff;
}

html .call-to-action.featured.featured-dark {
	border-top-color: #212529;
}

html .call-to-action.call-to-action-dark {
	background: #212529;
}

html .call-to-action.with-full-borders-dark {
	border-color: #212529;
}

html .call-to-action.call-to-action-dark {
	background: #212529;
}

html .call-to-action.call-to-action-dark h3,
html .call-to-action.call-to-action-dark p {
	color: #ffffff;
}

html .call-to-action.featured.featured-light {
	border-top-color: #ffffff;
}

html .call-to-action.call-to-action-light {
	background: #ffffff;
}

html .call-to-action.with-full-borders-light {
	border-color: #ffffff;
}

html .call-to-action.call-to-action-light {
	background: #ffffff;
}

html .call-to-action.call-to-action-light h3,
html .call-to-action.call-to-action-light p {
	color: #777777;
}

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
	background: #47648b;
	-webkit-box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #47648b;
	box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #47648b;
}

ul.history li .featured-box .box-content {
	border-top-color: #47648b;
}

.recent-posts .date .month,
article.post .post-date .month {
	background-color: #47648b;
}

.recent-posts .date .day,
article.post .post-date .day {
	color: #47648b;
}

.slider .tp-bannertimer {
	background-color: #47648b;
}

.home-concept strong {
	color: #47648b;
}

html .home-intro-primary {
	background: #47648b;
}

html .home-intro-secondary {
	background: #484a51;
}

html .home-intro-tertiary {
	background: #2baab1;
}

html .home-intro-quaternary {
	background: #383f48;
}

html .home-intro-dark {
	background: #212529;
}

html .home-intro-light {
	background: #ffffff;
}

html .slide-overlay-primary .slotholder:after {
	background-color: #47648b !important;
}

html .slider-container .tp-caption-overlay-primary {
	background: #47648b;
}

html .slider-container .tp-caption-overlay-opacity-primary {
	background: rgba(71, 100, 139, 0.4);
}

html .tp-bullets.bullets-color-primary .tp-bullet:hover,
html .tp-bullets.bullets-color-primary .tp-bullet.selected {
	background: #47648b;
}

html .slide-overlay-secondary .slotholder:after {
	background-color: #484a51 !important;
}

html .slider-container .tp-caption-overlay-secondary {
	background: #484a51;
}

html .slider-container .tp-caption-overlay-opacity-secondary {
	background: rgba(72, 74, 81, 0.4);
}

html .tp-bullets.bullets-color-secondary .tp-bullet:hover,
html .tp-bullets.bullets-color-secondary .tp-bullet.selected {
	background: #484a51;
}

html .slide-overlay-tertiary .slotholder:after {
	background-color: #2baab1 !important;
}

html .slider-container .tp-caption-overlay-tertiary {
	background: #2baab1;
}

html .slider-container .tp-caption-overlay-opacity-tertiary {
	background: rgba(43, 170, 177, 0.4);
}

html .tp-bullets.bullets-color-tertiary .tp-bullet:hover,
html .tp-bullets.bullets-color-tertiary .tp-bullet.selected {
	background: #2baab1;
}

html .slide-overlay-quaternary .slotholder:after {
	background-color: #383f48 !important;
}

html .slider-container .tp-caption-overlay-quaternary {
	background: #383f48;
}

html .slider-container .tp-caption-overlay-opacity-quaternary {
	background: rgba(56, 63, 72, 0.4);
}

html .tp-bullets.bullets-color-quaternary .tp-bullet:hover,
html .tp-bullets.bullets-color-quaternary .tp-bullet.selected {
	background: #383f48;
}

html .slide-overlay-dark .slotholder:after {
	background-color: #212529 !important;
}

html .slider-container .tp-caption-overlay-dark {
	background: #212529;
}

html .slider-container .tp-caption-overlay-opacity-dark {
	background: rgba(33, 37, 41, 0.4);
}

html .tp-bullets.bullets-color-dark .tp-bullet:hover,
html .tp-bullets.bullets-color-dark .tp-bullet.selected {
	background: #212529;
}

html .slide-overlay-light .slotholder:after {
	background-color: #ffffff !important;
}

html .slider-container .tp-caption-overlay-light {
	background: #ffffff;
}

html .slider-container .tp-caption-overlay-opacity-light {
	background: rgba(255, 255, 255, 0.4);
}

html .tp-bullets.bullets-color-light .tp-bullet:hover,
html .tp-bullets.bullets-color-light .tp-bullet.selected {
	background: #ffffff;
}

html .slide-overlay.slide-overlay-gradient .slotholder:after {
	background-color: #2baab1 !important;
	background-image: -webkit-gradient(linear, left top, right top, from(#2baab1), to(#383f48)) !important;
	background-image: linear-gradient(to right, #2baab1 0%, #383f48 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2baab1', endColorstr='#383f48', GradientType=1);
}

.tparrows.tparrows-carousel.tp-leftarrow,
.tparrows.tparrows-carousel.tp-rightarrow {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
	color: #ffffff;
}

.tparrows.tparrows-carousel.tp-leftarrow:hover,
.tparrows.tparrows-carousel.tp-rightarrow:hover,
.tparrows.tparrows-carousel.tp-leftarrow.hover,
.tparrows.tparrows-carousel.tp-rightarrow.hover {
	background-color: #5476a4;
	border-color: #5a7dab #5a7dab #47648b;
	color: #ffffff;
}

.tparrows.tparrows-carousel.tp-leftarrow:focus,
.tparrows.tparrows-carousel.tp-rightarrow:focus,
.tparrows.tparrows-carousel.tp-leftarrow.focus,
.tparrows.tparrows-carousel.tp-rightarrow.focus {
	-webkit-box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
	box-shadow: 0 0 0 3px rgba(71, 100, 139, 0.5);
}

.tparrows.tparrows-carousel.tp-leftarrow.disabled,
.tparrows.tparrows-carousel.tp-rightarrow.disabled,
.tparrows.tparrows-carousel.tp-leftarrow:disabled,
.tparrows.tparrows-carousel.tp-rightarrow:disabled {
	background-color: #47648b;
	border-color: #47648b #47648b #364c69;
}

.tparrows.tparrows-carousel.tp-leftarrow:active,
.tparrows.tparrows-carousel.tp-rightarrow:active,
.tparrows.tparrows-carousel.tp-leftarrow.active,
.tparrows.tparrows-carousel.tp-rightarrow.active {
	background-color: #3a5272 !important;
	background-image: none !important;
	border-color: #364c69 #364c69 #253347 !important;
}

.tparrows.arrows-primary:before {
	color: #47648b;
}

.shop .products .product .onsale {
	background-color: #47648b;
	border-bottom-color: #395170;
}

.shop .star-rating span,
.shop .cart-totals tr.total .amount {
	color: #47648b;
}

#footer .footer-ribbon {
	background: #47648b;
}

#footer .footer-ribbon:before {
	border-right-color: #2d4058;
	border-left-color: #2d4058;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active {
	color: #47648b !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active:before {
	background: #47648b !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a {
	color: #47648b !important;
}

#footer .footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a:before {
	background: #47648b !important;
}

.scrollable.colored-slider .scrollable-slider {
	background: #47648b;
}

.sample-icon-list .sample-icon a:hover {
	background-color: #47648b;
}

html.boxed .body {
	border-top-color: #47648b;
}
/*
* Who We Are
*/
.custom-image-style-1 {
	position: relative;
	border-radius: 100%;
	border: 4px solid #FFF;
	-webkit-box-shadow: 0px 7px 60px -15px rgba(0, 0, 0, 0.5);
					box-shadow: 0px 7px 60px -15px rgba(0, 0, 0, 0.5);
	z-index: 2;
}
@media (min-width: 767px) {
	.custom-image-style-1:not(._middle) {
		position: absolute;
		top: 30%;
		z-index: 1;
	}
	.custom-image-style-1._left {
		left: 1%;
	}
	.custom-image-style-1._left_2 {
		right:32%;
		top:-25%;
	}
}
@media (min-width: 767px) and (min-width: 992px) and (max-width: 1199px) {
	.custom-image-style-1._left {
		left: 0%;
	}
}
@media (min-width: 767px) and (max-width: 991px) {
	.custom-image-style-1._left {
		left: 11.2%;
	}
}
@media (min-width: 767px) {
	.custom-image-style-1._middle {
		top: 23%;
	}
	.custom-image-style-1._right {
		left: 68%;
	}
}

@media (min-width: 768px) {
	.custom-height {
		height: 210px;
	}
}

